import { useContext } from 'react';
import { CartContext } from '../../context/cart-context';
import { UserContext } from '../../context/user-context';
import { isInCart } from '../../helpers';
import withRouter from '../with-router';
import './featured-product.styles.scss';

const FeaturedProduct = (props) => {
  const { id, title, imageUrl, price, unit, navigate, description, minQty, active //, locatn
    , setShopping, setbAdd, setbInc, setPrd, modalShown } = props, dt = new Date();
  const { user } = useContext(UserContext); //console.log("Context user:", user);
  let day = ''; //console.log('dt:', dt, dt.getDay());
  // switch(dt.getDay()) { // 0: Sun
  //   case 6: case 0: day = 'Mon'; break; // Sat, Sun
  //   case 1: case 2: case 3: day = 'Thu'; break; // Mon,Tue,Wed
  //   case 4: case 5: day = 'Sat'; break; // Thu, Fri
  //   default: day = dt.getDay().toString();
  // } //console.log(dt.getDay(), ', day:', day);
  let mn = (dt.getMonth()+1).toString(); if(mn.length<2) mn = '0' + mn;
  let dy = (dt.getDate()+1).toString(); if(dy.length<2) dy = '0' + dy;
  day = `${dt.getFullYear()}-${mn}-${dy}`; //console.log('day:', day);
  const product = { id, title, imageUrl, price, unit, subTyp: 'One off', delDay: day
    , description, minQty, active }; //console.log("product:", product);
  const { addProduct, cartItems, increase } = useContext(CartContext);
  const itemInCart = isInCart(product, cartItems); //console.log('itemInCart:', itemInCart);
  return (
    <div className='featured-product'>
      <div className='featured-image' onClick={() => navigate(`/product/${id}`)}>
        <img src={imageUrl} alt='product' />
      </div>
      <div className='name-price'>
        <h3>{title}</h3>
        <p>Tk {price} {unit? '/ '+unit: ''}</p>
        {/* { !itemInCart && <button disabled={!product.active}
          className='button is-black nomad-btn' onClick={() => { //if(!locatn|| locatn===' ')
          setShopping(true); setPrd(product); if(setbAdd) setbAdd(true); else
          if(modalShown) addProduct(product); }}>ADD TO CART</button>
        } */}
        { !itemInCart && <div style={{display:'flex',margin:0}}>
          <button disabled={!product.active} style={{marginRight:'10px',backgroundColor:'rgb(50,90,120)'}}
            className='button is-black org-btn' onClick={() => { addProduct(product);
            if(user) navigate('/cart'); else {
            sessionStorage.setItem('nextPage', 'cart'); navigate('/sign-in'); }
          }}>BUY NOW</button>
          <button disabled={!product.active} style={{backgroundColor:'rgb(90,100,30)'}}
            className='button is-black org-btn' onClick={() => { //if(!locatn|| locatn===' ')
            setShopping(true); setPrd(product); if(setbAdd) setbAdd(true); else
          if(modalShown) addProduct(product); }}>ADD TO CART</button>
        </div>}
        {/* { itemInCart && <button disabled={!product.active}
          className='button is-white nomad-btn' onClick={() => {
          setShopping(true); setPrd(product); if(setbInc) setbInc(true); else
          if(modalShown) increase(product); }} id='btn-white-outline'>ADD MORE</button>
        } */}
        { itemInCart && <div style={{display:'flex',margin:0}}>
          <button disabled={!product.active} style={{marginRight:'10px',backgroundColor:'rgb(50,90,120)'}}
            className='button is-black org-btn' onClick={() => { //increase(product);
            if(user) navigate('/cart'); else {
            sessionStorage.setItem('nextPage', 'cart'); navigate('/sign-in'); }
          }}>BUY NOW</button>
          <button disabled={!product.active}
            className='button is-white nomad-btn' onClick={() => {
            // setShopping(true); setPrd(product); if(setbInc) setbInc(true); else if(modalShown)
          increase(product); }} id='btn-white-outline'>ADD MORE</button>
        </div>}
        { itemInCart && <div style={{display:'flex',margin:0}}>
          Qty in Cart: &nbsp; <b>{itemInCart.quantity}</b> &nbsp;{itemInCart.unit}
        </div>}
      </div>
    </div>
  );
};

export default withRouter(FeaturedProduct);
