import { useContext, useEffect, useState } from 'react';
import { OrdersContext } from '../../../context/orders-context';
import { ProductsContext } from '../../../context/products-context';
import { UserContext } from '../../../context/user-context';
import { Formik, Field, Form } from 'formik';
import { fetchFromAPI } from '../../../helpers';
import Layout from '../../shared/layout';
import CustOrder from '../orders-page/cust-order';
import DeliverySchedule from './delv-schd';
import Cow from './cow';
import MilkSale from './milk-sale';
import User from './user';
import './admin-page.styles.scss';

const milkSaleValidate = values => { const errors = {};
  if(!values.colDt) errors.colDt = 'Required';
  if(!values.qty) errors.qty = 'Required';
  if(!values.value) errors.value = 'Required'; return errors;
}
const userValidate = values => { const errors = {};
  if(!values.phone) errors.phone  = 'Phone number is Required';
  else if(!/^01[0-9]{8,}$/i.test(values.phone)) errors.phone = 'Invalid phone number';
  if(!values.firstname) errors.firstname  = 'Name is Required';
  if(!values.password) errors.password = 'Password is Required';
  else if(values.password.length<6) errors.password = 'Password must be at least 6 characters long';
  return errors;
}
const cowValidate = values => { const errors = {};
  if(!values.tagNo) errors.tagNo  = 'Tag number is Required';
  if(!values.purchDt) errors.purchDt = 'Purchase date is Required';
  return errors;
}
const orderValidate = values => { const errors = {};
  if(!values.phone) errors.phone  = 'Mobile number is Required';
  else if(!/^01[0-9]{8,}$/i.test(values.phone)) errors.phone = 'Invalid mobile number';
  if(!values.name) errors.name = 'Customer name is Required';
  if(!values.address) errors.address = 'Address is Required';
  return errors;
}

const AdminPage = () => {
  const [ option, setOption ] = useState('orders'); //daily-inputs
  const [ shOrFrm, setShOrFrm ] = useState(false);
  const menuHandler = (option) => setOption(option);
  const [ change, setChange ] = useState([]);
  const changelHandler = (i) => { console.log('changelHandler:: change:', change, ', i:', i);
    setChange(oldVal => { const newVal = [...oldVal];
      newVal[i] = !(newVal[i]); return newVal;
    });
  };
  const { orders, prepareOrder, assignOrder, deliverOrder, cancelOrder, reviveOrder, deleteOrder,
    refundOrder, reOrder, increase, decrease, changeSubtyp, changeDelday, refreshOrders } = useContext(OrdersContext);
  const funcs = { prepareOrder, assignOrder, deliverOrder, cancelOrder, reviveOrder, deleteOrder,
    refundOrder, reOrder, increase, decrease, changeSubtyp, changeDelday }; //console.log('orders:',orders);
  const { user } = useContext(UserContext), { activeProducts } = useContext(ProductsContext);
  //console.log('activeProducts:', activeProducts);
  const [ product, setProduct ] = useState(
    activeProducts&& activeProducts.length>0? activeProducts[0]: null);

  const [sales, setSales] = useState([]), [due, setDue] = useState(0);
  const [cows, setCows] = useState([]);
  const [users, setUsers] = useState([]), [error, setError] = useState(null);
  const getMilkSales = async () => { //console.log('Getting milk sales:');
    await fetchFromAPI('get-milk-sales', {method: 'GET'}).then(res => { //console.log("res:", res);
      if(res && res.milkSales) {
        res.milkSales.sort((o1, o2) => {
          const d1 = new Date(o1.colDt), d2 = new Date(o2.colDt);
          //console.log('d1:', d1, ', d2:', d2, ', d1-d2', (d1-d2));
          return d2 - d1;
        }); if(res.due) setDue(res.due);
        setSales(res.milkSales); //console.log('res.milkSales:', res.milkSales);
      } else return false;
    });
  };
  const getCows = async () => {
    const ret = await fetchFromAPI('get-cows', {method: 'GET'}).then(res => { //console.log('res:', res);
      if(res && res.cows) { //console.log('res.cows:', res.cows);
        setCows(res.cows); return true;
      } else return false;
    }); return ret; //console.log('ret:', ret);
  };
  const getUsers = async () => {
    const ret = await fetchFromAPI('get-users', {method: 'GET'}).then(res => { //console.log('res:', res);
      if(res && res.users) { //console.log('res.users:', res.users);
        setUsers(res.users); return true;
      } else return false;
    }); return ret; //console.log('ret:', ret);
  };
  useEffect(() => { getMilkSales(); getCows(); getUsers(); }, []);

  const initialCowValues = { tagNo: '', purchDt: '', bodyWt: '', age: '', insurance: '' };
  const handleCowSave = (values, {resetForm}) => { setTimeout(async () => { //setSubmitting
    console.log('handleCowSave:: values', values);
    const res = await fetchFromAPI('save-cow', {body: values}); console.log('res:', res);
    resetForm(); //getMilkSales(); //setSubmitting(false)
  }, 400); };

  const initialSaleValues = { colDt: '', qty: '', value: '', amt: '', due: '' };
  const handleMilkSave = (values, {resetForm}) => { setTimeout(async () => { //setSubmitting
      console.log('handleMilkSave:: values', values);
      const res = await fetchFromAPI('save-milk-sale', {body: values}); console.log('res:', res);
      resetForm(); getMilkSales(); //setSubmitting(false)
  }, 400); };

  const locatn = localStorage.getItem('locatn');
  const initialOrderValues = { orDt: '', phone: '', name: '', email: '', title: '', qty: '',
    address: '', subTyp: '', delDay: '', locatn, pmtMethod: 'COD' };
  const handleOrderSave = (values, {resetForm}) => { setTimeout(async () => { //setSubmitting
    console.log('handleOrderSave:: values', values);
    const { orDt, phone, name, email, title, qty, address, subTyp, delDay, locatn, pmtMethod } = values;
    localStorage.setItem('locatn', locatn);
    const found = activeProducts.find(pr => pr.title===title);
    if(!found) return; console.log('found:', found);
    const items = [{ id: found.id, title, price: found.price, quantity: qty, unit: found.unit, subTyp, delDay }];
    const total = items.reduce((total, item) => total + item.quantity*item.price, 0);
    const shipping = { name, address, email, locatn, phone }; //: new Date()
    const body = { orDt, items, total, pmtMethod, shipping }; console.log('body:', body);
    const res = await fetchFromAPI('back-order', {body}); console.log("handleCheckout:: res:", res);
    if(res&& res.orderDoc) { resetForm(); refreshOrders(); //setSubmitting(false)
      setTimeout(() => setShOrFrm(false), 1200);
    }
  }, 400); };

  const initialUserValues = { firstname: '', phone: '', email: '', password: '', type: '' };
  const handleUserSave = (values, {resetForm}) => { setTimeout(async () => {
    let { firstname, phone, email, password, type } = values; console.log('handleUserSave:: values', values);
    if(!email) email = 'p_' + phone + '@test.com'; console.log('email:', email);
    if(firstname) {
      const updateUser = async () => {
        const body = { displayName: firstname, phoneNumber: '+88' + phone, email, password, type };
        const res = await fetchFromAPI('update-user', {body}); console.log('res:', res);
      };
      try { updateUser(); resetForm(); setError(null);
        if(!getUsers()) setTimeout(() => {
          if(!getUsers()) setTimeout(() => { getUsers(); }, 1900);
        }, 1900);
      } catch(error) { console.log('Error updating user:', error);
        resetForm(); setError(error);
      }
    }
  }, 400); };

  return (
    <Layout>
      <div className='admin-page'>
        <nav className='menu'>
          <ul>
            <li onClick={menuHandler.bind(null, 'cows')}>Cows</li>
            <li onClick={menuHandler.bind(null, 'orders')}>Orders</li>
            <li onClick={menuHandler.bind(null, 'delvSchd')}>Delivery Schedule</li>
            <li onClick={menuHandler.bind(null, 'users')}>Users</li>
            <li onClick={menuHandler.bind(null, 'daily-inputs')}>Daily Inputs</li>
          </ul>
          {option==='orders'&& <ul className='add-order'>
            <li onClick={()=> setShOrFrm(!shOrFrm)}>ADD ORDER</li>
          </ul>}
        </nav>
        <div className='page-content'>
          {/* <--------------------------------------------------------------------------------> */}
          <div className='delv-schd' style={{display: option==='delvSchd'? 'block': 'none'}}>
            <h1>Delivery Schedule</h1>
            <div className='sales-container'>
              <DeliverySchedule orders={orders}  />
            </div>
          </div>
          {/* <--------------------------------------------------------------------------------> */}
          <div className='cows' style={{display: option==='cows'? 'block': 'none'}}>
            <h1>Cows</h1>
            <div className='form-container'>
              <Formik initialValues={initialCowValues} validate={cowValidate}
                onSubmit={handleCowSave //values => {console.log(values)}
                }>{({values, errors, handleChange, handleSubmit, isSubmitting}) => {
                  const { tagNo, purchDt } = errors, dt = new Date(); //console.log('errors:', errors);
                  const mn = (dt.getMonth()+1).toString(), dy = (dt.getDate()).toString();
                  if(!values.purchDt) values.purchDt = `${dt.getFullYear()}-${(mn.length<2?'0':'')+mn}-${(dy.length<2?'0':'')+dy}`;
                  return <Form className='data-form'>
                    <div><label htmlFor='tagNo'>Tag number</label>
                      <Field type='text' id='tagNo' name='tagNo' placeholder='Tag number'
                      className={'nomad-input ' + (tagNo? 'error': '')} required /></div>
                    <div><label htmlFor='purchDt'>Purchase Date</label>
                      <Field type='date' id='purchDt' name='purchDt' placeholder='Purchase Date'
                      className={'nomad-input ' + (purchDt? 'error': '')} required /></div>
                    <div><label htmlFor='bodyWt'>Body weight (in Kg)</label>
                      <Field type='number' id='bodyWt' name='bodyWt' placeholder='Body weight (in Kg)'
                      className={'nomad-input '} step='0.01' /></div>
                    <div><label htmlFor='age'>Age</label>
                      <Field type='text' id='age' name='age' placeholder='Age'
                      className={'nomad-input '} /></div>
                    <div><label htmlFor='insurance'>Insurance coverage</label>
                      <Field type='text' id='insurance' name='insurance' placeholder='Insurance coverage'
                      className={'nomad-input '} /></div>
                    {(tagNo|| purchDt) && <div className='error-message'>
                      { <p>{tagNo? tagNo: purchDt}</p> }
                    </div>}
                    {!error && <div className='submit-btn'><button type='submit' disabled={isSubmitting}
                      className='button is-black nomad-btn submit'>Submit</button></div>}
                    <div className='error-message'>{ error && <p>{error.message}</p> }</div>
                  </Form>
                }}
              </Formik>
            </div>
            <div className='cows-container'>
              <Cow  />
              {
                cows.map((cow, i) => <Cow {...cow} key={i} />)
              }
            </div>
          </div>
          {/* <--------------------------------------------------------------------------------> */}
          <div className='orders' style={{display: option==='orders'? 'block': 'none'}}>
            <h1>Orders</h1>
            <div className='form-container'>
              {shOrFrm&& <Formik initialValues={initialOrderValues} validate={orderValidate}
                onSubmit={handleOrderSave //values => {console.log(values)}
                }>{({values, errors, isSubmitting, setValues}) => { //console.log('values:', values);
                  const { phone, name, address } = errors, dt = new Date(); //console.log('errors:', errors);
                  const mn = (dt.getMonth()+1).toString(), dy = (dt.getDate()).toString();
                  if(!values.orDt) values.orDt = `${dt.getFullYear()}-${(mn.length<2?'0':'')+mn}-${(dy.length<2?'0':'')+dy}`;
                  if(!values.title && activeProducts.length>0) {
                    values.title =  activeProducts[0].title;
                    values.qty = activeProducts[0].minQty; //setProduct(activeProducts[0]);
                  }
                  if(!values.subTyp) values.subTyp = 'One off';
                  if(!values.delDay) { const dt = new Date();
                    let mn = (dt.getMonth()+1).toString(); if(mn.length<2) mn = '0' + mn;
                    let dy = (dt.getDate()+1).toString(); if(dy.length<2) dy = '0' + dy;
                    values.delDay = `${dt.getFullYear()}-${mn}-${dy}`;
                  }
                  return <Form className='data-form'>
                    <div><label htmlFor='orDt'>Order Date</label>
                      <Field type='date' id='orDt' name='orDt' placeholder='Order Date'
                      className={'nomad-input '} required /></div>

                    <div><label htmlFor='title'>Mobile Number</label><Field type='text'
                      id='phone' name='phone' placeholder='Mobile Number' onBlur={async e => {
                        console.log('onBlur:: e.target.value:',e.target.value);
                        const url = 'get-user-name?phone='+e.target.value; console.log('url:', url);
                        const res = await fetchFromAPI(url, {method: 'GET'}); console.log('res:', res);
                        if(res&& res.displayName) { setValues(values => {
                          if(res.email) values.email = res.email;
                          values.name = res.displayName; console.log('values:', values);
                          //await new Promise(r => setTimeout(r, 18000)); console.log('waited 18 seconds');
                          return values;
                        }); }
                      }} className={'nomad-input ' + (phone? 'error': '')} required /></div>
                    <div><label htmlFor='name'>Customer Name</label><Field type='text' id='name'
                      name='name' placeholder='Customer Name' disabled={phone|| !values.phone}
                      className={'nomad-input '+(name?'error':'')} required /></div>

                    <div><label htmlFor='email'>Email (Optional)</label>
                      <Field type='email' id='email' name='email' placeholder='Email (Optional)'
                      className={'nomad-input '} disabled={phone|| !values.phone} /></div>

                    <div><label htmlFor='title'>Product</label><Field as='select' id='title'
                      name='title' onBlur={e => { const prd = product? product: activeProducts[0];
                        //console.log('onBlur:: e.target.value:',e.target.value,', product:',product);
                        if(prd.title!==e.target.value) { console.log('Finding',e.target.value);
                          const found = activeProducts.find(pr => pr.title===e.target.value);
                          if(found) setProduct(found);
                        }
                      }} className={'nomad-input'}>{ activeProducts.map(product => <option
                        value={product.title} key={product.title}>{product.title}</option>)
                      }
                    </Field></div>
                    <div><label htmlFor='qty'>Quantity (in Litres)</label><Field type='number'
                      id='qty' name='qty' placeholder='Quantity (in Litres)' step='0.01'
                      className={'nomad-input '} min={product?product.minQty:1} /></div>
                    <div><label htmlFor='subTyp'>Subscription Type</label><Field as='select'
                      id='subTyp' name='subTyp' onBlur={e => { let dt = new Date();
                        console.log('onBlur:: e.target.value:', e.target.value);
                        if(e.target.value==='Weekly') {
                          const days =['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
                          if(values.delDay.length<3) { let dy = dt.getDay()+1; if(dy>6) dy = 0;
                            //setWeekDay(days[dy]);
                            setValues(values => {values.delDay = days[dy]; return values;});
                          } else if(values.delDay.length>3) { dt = new Date(values.delDay);
                            //setWeekDay(days[dt.getDay()]);
                            setValues(values => {values.delDay = days[dt.getDay()]; return values;});
                            //console.log('dt:', dt, dt.getDay(), days[dt.getDay()]);
                          }
                        } else if(e.target.value!=='Weekly' && values.delDay.length<4) {
                          let mn = (dt.getMonth()+1).toString(); if(mn.length<2) mn = '0' + mn;
                          let dy = (dt.getDate()+1).toString(); if(dy.length<2) dy = '0' + dy;
                          //setMnthDay(`${dt.getFullYear()}-${mn}-${dy}`);
                          setValues(values => {
                            values.delDay = `${dt.getFullYear()}-${mn}-${dy}`; return values;
                          });
                        }
                      }}
                      className={'nomad-input'}>
                      <option value='Daily'>Daily</option>
                      <option value='Weekly'>Weekly</option>
                      <option value='One off'>One off</option>
                    </Field></div>
                    <div><label htmlFor='delDay'>{values.subTyp==='Daily'?'Starting From':
                      'Delivery Day'}</label>{values.subTyp==='Weekly'? <Field as='select'
                      id='delDay' name='delDay' className={'nomad-input'}>
                      <option value='Sat'>Saturday</option>
                      <option value='Sun'>Sunday</option>
                      <option value='Mon'>Monday</option>
                      <option value='Tue'>Tuesday</option>
                      <option value='Wed'>Wednesday</option>
                      <option value='Thu'>Thursday</option>
                      <option value='Fri'>Friday</option>
                    </Field>: <Field type='date' id='delDay' name='delDay'
                      className={'nomad-input'} />}</div>
                    <div><label htmlFor='address'>Address</label><Field type='text' id='address'
                      name='address' placeholder='Address' disabled={phone|| !values.phone}
                      className={'nomad-input '+(address?'error':'')} required /></div>
                    <div><label htmlFor='locatn'>Location</label><Field as='select'
                      id='locatn' name='locatn' className={'nomad-input'}>
                      <option value='Banani'>Banani</option>
                      <option value='Banani DOHS'>Banani DOHS</option>
                      <option value='Mohakhali DOHS'>Mohakhali DOHS</option>
                      <option value='Gulshan'>Gulshan</option>
                      <option value='Baridhara'>Baridhara</option>
                      <option value='Baridhara DoHS'>Baridhara DoHS</option>
                    </Field></div>
                    <div><label htmlFor='pmtMethod'>Payment Method</label>
                      <div id='pmtMethod' className={'nomad-input'}>
                        <Field type='radio' id='cod' value='COD' name='pmtMethod' />
                        <label htmlFor='cod'>Cash on Delivery</label><br />
                        <Field type='radio' id='bkash' value='bKash' name='pmtMethod' />
                        <label htmlFor='bKash'>bKash merchant a/c 01886200856</label>
                      </div>
                    </div>
                    {(phone|| name|| address) && <div className='error-message'>
                      { <p>{phone? phone: (name? name: address)}</p> }
                    </div>}
                    {!error && <div className='submit-btn'><button type='submit' disabled={isSubmitting}
                      className='button is-black nomad-btn submit'>Submit</button></div>}
                    <div className='error-message'>{ error && <p>{error.message}</p> }</div>
                  </Form>
                }}
              </Formik>}
            </div>
            <div className='order-container'>
              {
                orders.map((order, i) => <CustOrder {...order} {...funcs}
                change={change[i]} changelHandler={changelHandler.bind(null,i)}
                admin={['A','M','E'].includes(user.type)} key={order.id} />)
              }
            </div>
          </div>
          {/* <--------------------------------------------------------------------------------> */}
          <div className='users' style={{display: option==='users'? 'block': 'none'}}>
            <h1>Users</h1>
            <div className='form-container'>
              <Formik initialValues={initialUserValues} validate={userValidate}
                onSubmit={handleUserSave //values => {console.log(values)}
                }>{({values, errors, handleChange, handleSubmit, isSubmitting}) => {
                  const { firstname, phone, password } = errors; //console.log('errors:', errors);
                  return <Form className='data-form'>
                    <div><label htmlFor='firstname'>Name</label>
                      <Field type='text' id='firstname' name='firstname' placeholder='Name'
                      className={'nomad-input ' + (firstname? 'error': '')} required /></div>
                    <div><label htmlFor='phone'>Mobile Number</label>
                      <Field type='text' id='phone' name='phone' placeholder='Mobile Number'
                      className={'nomad-input ' + (phone? 'error': '')} required /></div>
                    <div><label htmlFor='email'>Email (Optional)</label>
                      <Field type='email' id='email' name='email' placeholder='Email (Optional)'
                      className={'nomad-input '} /></div>
                    <div><label htmlFor='password'>Password</label>
                      <Field type='password' id='password' name='password' placeholder='Password'
                      className={'nomad-input ' + (password? 'error': '')} required /></div>
                    <div><label htmlFor='type'>Type</label>
                      <Field type='text' id='type' name='type' placeholder='Type'
                      className={'nomad-input '} /></div>
                    {(firstname|| phone|| password) && <div className='error-message'>
                      { <p>{firstname? firstname: (phone? phone: password)}</p> }
                    </div>}
                    {!error && <div className='submit-btn'><button type='submit' disabled={isSubmitting}
                      className='button is-black nomad-btn submit'>Submit</button></div>}
                    <div className='error-message'>
                      { error && <p>{error.message}</p> }
                    </div>
                  </Form>
                }}
              </Formik>
            </div><hr />
            <div className='user-container'>
              <User heading={true}  />
              {
                users.map((usr, i) => <User {...usr} key={i} />)
              }
            </div>
          </div>
          {/* <--------------------------------------------------------------------------------> */}
          <div className='milk-sales' style={{display: option==='daily-inputs'? 'block': 'none'}}>
            <h1>Milk Collection & Delivery</h1>
            <div className='form-container'>
              <Formik initialValues={initialSaleValues} validate={milkSaleValidate}
                onSubmit={handleMilkSave //values => {console.log(values)}
                }>
                {({values, errors, isSubmitting}) => { // values, handleChange, handleSubmit,
                  const { colDt, qty, value } = errors, dt = new Date(); //, due = 0;
                  const mn = (dt.getMonth()+1).toString(), dy = (dt.getDate()).toString();
                  if(!values.colDt) values.colDt = `${dt.getFullYear()}-${(mn.length<2?'0':'')+mn}-${(dy.length<2?'0':'')+dy}`;
                  const val = parseFloat(values.value), amt = parseFloat(values.amt);
                  if(!isNaN(val) && !isNaN(amt)) values.due = Math.round((due+val-amt)*100)/100;
                  else values.due = due; //console.log('values:', values);
                  return <Form className='data-form'>
                    <div><label htmlFor='colDt'>Collection Date</label>
                      <Field type='date' id='colDt' name='colDt' placeholder='Collection Date'
                      className={'nomad-input ' + (colDt? 'error': '')} required /></div>
                    <div><label htmlFor='qty'>Milk Collected (in Ltr)</label>
                      <Field type='number' id='qty' name='qty' placeholder='Milk Collected (in Ltr)'
                      className={'nomad-input ' + (qty? 'error': '')} step='0.01' /></div>
                    <div><label htmlFor='value'>Milk Value (Taka)</label>
                      <Field type='number' id='value' name='value' placeholder='Milk Value (Taka)'
                      className={'nomad-input ' + (value? 'error': '')} step='0.01' /></div>
                    <div><label htmlFor='amt'>Sale proceeds (Taka)</label>
                      <Field type='number' id='amt' name='amt' placeholder='Sale proceeds (Taka)'
                      className={'nomad-input ' } step='0.01' /></div>
                    <div><label htmlFor='due'>Due (Taka)</label>
                      <Field type='number' id='due' name='due' placeholder='Due (Taka)'
                      className={'nomad-input ' } step='0.01' disabled={true} /></div>
                    <div className='submit-btn'><button type='submit' disabled={isSubmitting}
                      className='button is-black nomad-btn submit'>Submit</button></div>
                  </Form>
                }}
              </Formik>
            </div><hr />
            <div className='sales-container'>
              <MilkSale  />
              {
                sales.map((sale, i) => <MilkSale {...sale} key={i} />)
              }
            </div>
          </div>
          {/* <--------------------------------------------------------------------------------> */}
        </div>
      </div>
    </Layout>
  );
};

export default AdminPage;
