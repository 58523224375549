import { useContext, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { CartContext } from '../../context/cart-context';
import { UserContext } from '../../context/user-context';
//import SignIn from '../../components/sign-in/sign-in';
import Layout from '../shared/layout';
//import StripeCheckout from './stripe-checkout/stripe-checkout';
import ShippingAddress from './custom-checkout/shipping-address';
import CustomCheckout from './custom-checkout/custom-checkout';
import './checkout.styles.scss';

const Checkout = () => {
  const { itemCount, total, cartItems, clearCart } = useContext(CartContext);
  const [ searchParams ] = useSearchParams(), navigate = useNavigate();
  const status = searchParams.get('status'), paymentID = searchParams.get('paymentID');
  const bkash = status? { status, paymentID }: null;
  const shpFmStor = sessionStorage.getItem('shipping'); console.log('shpFmStor:', shpFmStor);
  let shp = null; if(shpFmStor) { shp = JSON.parse(shpFmStor); } console.log('shp:', shp);
  const [ shipping, setShipping ] = useState(shp); //null
  const [ addrTaken, setAddrTaken ] = useState(shipping? true: false); //false
  console.log('status:', status, ', paymentID:', paymentID, ', shipping:', shipping);
  const { user, setUser } = useContext(UserContext);
  if(paymentID && status) {
    if(status==='failure') navigate('/order-failed' + '?bkTranStat=Incorrect');
    else if(status==='success' && !shipping) {
      let usr = user; if(!usr) {
        const usrFmStor = sessionStorage.getItem('user'); console.log('usrFmStor:', usrFmStor);
        if(usrFmStor) usr = JSON.parse(usrFmStor);
        //if(usr&& usr.uid) { setUser(usr); console.log('Session usr:', usr); }
      }
      if(usr) {
        const locatn = localStorage.getItem('locatn'), addrss = localStorage.getItem('addrss');
        let email = ''; if(usr.email && !(usr.email[0]==='p' && usr.email[1]==='_'))
        email = usr.email; setAddrTaken(true); setShipping({
          email, phone:usr.phone.substring(3), name: usr.displayName, locatn, address: addrss
      }); }
    }
  }
  const addressShown = { display: (shipping ? '' : 'block') }; //none
  const cardShown = { display: (shipping && addrTaken ? 'block' : 'none') };
  console.log('cardShown:', cardShown);
  return (
    <Layout>
      <div className='checkout'>
        <h2>Checkout Summary</h2>
        <h3>{`Total Items: ${itemCount}`}</h3>
        <h4>{`Amount to Pay: Tk.${total}/-`}</h4>
        {/* <StripeCheckout /> */}
        <div style={addressShown}>
          <ShippingAddress setShipping={setShipping} addrTaken={addrTaken} setAddrTaken={setAddrTaken} />
        </div>
        <div style={cardShown}>
          <CustomCheckout { ...{shipping, cartItems, total, clearCart, bkash} } />
        </div>
      </div>
    </Layout>
  );
};

export default Checkout;
