import './user.styles.scss';

const User = props => {
  const { uid, displayName, phoneNumber, email, type, createdAt, heading } = props;
  const dt = heading? 'Date': (createdAt? new Date(createdAt): ''); //console.log('dt:', dt, (typeof dt));
  let sDt; if((typeof dt)==='object') { sDt =
    `${dt.getDate()}/${dt.getMonth()+1}/${dt.getFullYear().toString().substring(2)}`;
  } else sDt = dt; //console.log('qty:', qty,(typeof qty));
  const sName = heading? 'Name': displayName;
  const sPhone = heading? 'Phone': phoneNumber.substring(3);
  const sEmail = heading? 'Email': email;
  const sType = heading? 'Type': type;
  return (<>
    <div className='user'>
      <div className='wide-line'>
        <div className='created-date'>{sDt}</div>
        <div className='name'>{sName}</div>
        <div className='phone'>{sPhone}</div>
        <div className='email'>{sEmail}</div>
        <div className='type'>
          {heading? sType: <h4>{sType}</h4>}
        </div>
      </div>
    </div><hr />
  </>);
};

export default User;
