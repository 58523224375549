// import { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
// import { CartContext } from '../../../context/cart-context';
import { useNavigate } from 'react-router-dom';
import Layout from '../../shared/layout';

const MailSent = () => {
  // const { clearCart, cartItems } = useContext(CartContext);
  const [ searchParams ] = useSearchParams();
  const sentStat = searchParams.get('sentStat');
  const navigate = useNavigate(); console.log('sentStat:', sentStat);
  // useEffect(() => {
  //   if(cartItems.length>0) clearCart();
  // }, [clearCart, cartItems]);
  return (
    <Layout>
      <div className='checkout'>
        <h1 style={{color:(sentStat==='true'?'rgb(0,95,0)':'rgb(255,0,0)')}}>Mail {sentStat==='true'? 'Success': 'Failure'}</h1>
        <p>{sentStat==='true'? 'Your Mail has successfully been sent.'
        : 'Sorry, your mail could not be sent.'}</p>
        <div>
          <button className='button is-black nomad-btn submit'
            onClick={() => navigate(-1)}>Go Back</button>
        </div>
      </div>
    </Layout>
  );
};

export default MailSent;
