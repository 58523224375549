import { Canvas, Page, Text, View, Document, Image } from '@react-pdf/renderer';
import numToWords from './num-to-words.js';
import styles from './invoice-doc.styles.js';

const InvcTempl = {};
const mnths = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

const replaceTemplate = (template, data=[]) => {
  let values = template; //console.log("template::", (typeof template), template, ", data:", data);
  const replaceText = (values) => {
    if((typeof values)==="string") {
      values = values.replace(/%(\w*)%/g, (m, key) => (data.hasOwnProperty(key)? data[key]: `%${key}%`));
    } else if((typeof values)==="object" && values !== null) {
      if(Array.isArray(values)) values = values.map((line) => replaceText(line, data));
      else Object.keys(values).forEach((key) => { values[key] = replaceText(values[key], data) });
    } //console.log("replaceTemplate:: values:", values);
    return values;
  };
  if((typeof template)==="string") values = (" "+template).slice(1);
  else if((typeof template)==="object" && template !== null)
    values = JSON.parse(JSON.stringify(template));
  return replaceText(values);
};

const commaFormat = (amount) => {
  const neg = amount < 0; if(neg) amount = -amount;
  amount = (Math.round(amount*1000))/1000;
  const thou = Math.floor(amount/1000), hund = amount % 1000;
  let sHnd = hund.toString();
  if(sHnd.includes(".")) { let c =sHnd.substring(sHnd.length-1);
    while(c==="0") { sHnd = sHnd.substring(0, sHnd.length-1);
      c = sHnd.substring(sHnd.length-1);
      if(c===".") { sHnd = sHnd.substring(0, sHnd.length-1); break; }
  } }
  let retVal = (neg?"-":"") + (thou? thou.toString()+",": "") +
    (hund? ((thou? (hund<10?"00":(hund<100?"0":"")): "") + sHnd): (thou?"000":"0"));
  let di = retVal.indexOf("."); if(di>0 && di<(retVal.length-4)) { //console.log("commaFormat:: retVal:", retVal);
    retVal = retVal.substring(0, di+4);
  }
  return retVal;
};

export const toHtml = (order) => { console.log(order);
  if(!order) return null; let str;
  const orDt = new Date(order.orDt); console.log('orDt:', orDt);
  const sDt = `${mnths[orDt.getMonth()]} ${orDt.getDate()}, ${orDt.getFullYear()}`;
  const camel2Kebab = camel => { let kebab = "";
    for(let i=0; i<camel.length; i++){
      kebab += (camel[i]===camel[i].toUpperCase()? "-"+camel[i].toLowerCase(): camel[i]);
    } return kebab;
  }
  const cssStr = obj => { let str = "";
    if(Array.isArray(obj)){ obj.forEach((ob) => {str += cssStr(ob); }); }
    else if((typeof obj)==="object" && obj) { //console.log("obj:", obj);
      const ex = ["flexGrow","fontFamily","fontSize"]; //
      for(let k in obj){
        if (obj.hasOwnProperty(k) && !ex.includes(k)) str += camel2Kebab(k)+":"+obj[k]+";";
    } } return str;
  }
  const strStyle = stl => { let str = ""; //console.log("stl:", stl);
    if(Array.isArray(stl)){ stl.forEach((st) => {str += strStyle(st); }); }
    else if((typeof stl)==="string" && stl) { str += stl + " "; }
    return str;
  };
  const replNLwBr = text => { let txt = "";
    txt = text.replace(/\n/g, "<br>"); return txt;
  };
  let invcStyle = (order.style?(Array.isArray(order.style)
    ?(order.style.map(style=>styles[style])):[styles[order.style]]
  ):[]); //console.log("invcStyle:", invcStyle);
  invcStyle.splice(0, 0, styles.section); console.log("invcStyle:", invcStyle);
  let html = `
    <div class="page">
      <div class="img-container">
        <img class="img" src="https://storage.googleapis.com/organic-orkobd/orkofarmslogo.png" />
      </div>
      <div style="${cssStr(invcStyle)}">
        <div class="big">ORKO FARMS</div>
        <div class="size10">BANIARCHALA, BHABANIPUR, GAZIPUR</div>
        <div class="right">${sDt}</div>
        <br />
        <h1 class="h1">Invoice</h1>
        <br />
        <div class="size14 bold">${order.shipping?.name}</div>
        <div class="size14">${order.shipping?.address}</div>
        <br /><br />
        <div class="line">
          <div class="size14 grey item">Item</div>
          <div class="size14 grey qty center">Quantity</div>
          <div class="size14 grey rate center">Rate</div>
          <div class="size14 grey item right pr12">Total</div>
        </div>`; order.items.map(item => html += `
        <div class="line">
          <div class="size14 item">${item.title}</div>
          <div class="size14 qty center">${item.quantity} ${item.unit}</div>
          <div class="size14 rate center">@ ${item.price}</div>
          <div class="size14 item right">BDT ${commaFormat(item.price*item.quantity)}</div>
        </div>`); html += `<br /><br />
        <div class="size14">BDT ${commaFormat(order.total)} ${numToWords(order.total)} only</div>
        <br /><br /><br /><br /><div class="size12 center">
          This is a computer-generated invoice and does not require any signature
        </div>
      </div>
      <div>
        <div class="line pb8">
          <div class="size12 col1 grey">*Milk produced from healthy cows</div>
          <div class="size12 col2 grey">*Preserve below 4&deg; Celsius/40&deg; Fahrenheit</div>
        </div>
        <div class="line">
          <div class="size12 col1 grey">*Unpasteurized Milk</div>
          <div class="size12 col2 grey">*Boil before drinking</div>
        </div>
      </div>
    </div>
  `;
  return html;
}

const InvoiceDoc = ({ order }) => { console.log('InvoiceDoc:: order:', order);
  if(!order) return null; console.log('order.orDt:', order.orDt);
  const orDt = new Date(order.orDt); console.log('orDt:', orDt);
  const sDt = `${mnths[orDt.getMonth()]} ${orDt.getDate()}, ${orDt.getFullYear()}`;
  let invcStyle = (order.style?(Array.isArray(order.style)
    ?(order.style.map(style=>styles[style])):[styles[order.style]]
  ):[]); console.log('sDt:', sDt); //console.log("invcStyle:", invcStyle);
  invcStyle.splice(0, 0, styles.section); console.log("invcStyle:", invcStyle);
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <Image style={styles.img} src="/orkofarms.png" />
        <Canvas style={styles.canvas} />
        <View style={invcStyle}>
          <Text style={styles.big}>ORKO FARMS</Text>
          <Text style={styles.size10}>BANIARCHALA, BHABANIPUR, GAZIPUR</Text>
          <Text>{'\n\n'}</Text>
          <Text style={styles.right}>{sDt}</Text>
          <Text>{'\n\n\n\n'}</Text>
          <Text style={styles.h1}>Invoice</Text>
          <Text>{'\n\n'}</Text>
          <Text style={styles.size14}>{order.shipping?.name}</Text>
          <Text style={styles.size14}>{order.shipping?.address}</Text>
          <Text>{'\n\n\n\n'}</Text>
          <View style={styles.line}>
            <Text style={[styles.size14,styles.grey,styles.flex3]}>Item</Text>
            <Text style={[styles.size14,styles.grey,styles.flex2,styles.center]}>Quantity</Text>
            <Text style={[styles.size14,styles.grey,styles.flex1,styles.center]}>Rate</Text>
            <Text style={[styles.size14,styles.grey,styles.flex1]}></Text>
            <Text style={[styles.size14,styles.grey,styles.flex1,styles.center]}>Total</Text>
          </View>
          {order.items.map(item => <View style={styles.line} key={item.id}>
            <Text style={[styles.size14,styles.flex3]}>{item.title}</Text>
            <Text style={[styles.size14,styles.flex2,styles.center]}>{`${item.quantity} ${item.unit}`}</Text>
            <Text style={[styles.size14,styles.flex1,styles.center]}>@ {`${item.price}`}</Text>
            <Text style={[styles.size14,styles.flex2,styles.right]}>BDT {
              `${commaFormat(item.price*item.quantity)}`}</Text>
          </View>)}
          <Text>{'\n\n\n\n'}</Text><Text style={[styles.size14]}>
            Total: &nbsp; BDT {commaFormat(order.total)} {`(${numToWords(order.total)})`} only</Text>
          <Text>{'\n\n\n\n \n\n\n\n'}</Text><Text style={[styles.size12,styles.center]}>
            This is a computer-generated invoice and does not require any signature</Text>
        </View>
        <View style={styles.footer}>
          <View style={[styles.line,styles.pb8]}>
            <Text style={[styles.size12,styles.flex1,styles.grey]}>*Milk produced from healthy cows</Text>
            <Text style={[styles.size12,styles.flex1,styles.grey]}>*Preserve below 4&deg; Celsius/40&deg; Fahrenheit</Text>
          </View>
          <View style={styles.line}>
            <Text style={[styles.size12,styles.flex1,styles.grey]}>*Unpasteurized Milk</Text>
            <Text style={[styles.size12,styles.flex1,styles.grey]}>*Boil before drinking</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoiceDoc;
