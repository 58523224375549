import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { CardNumberElement, CardExpiryElement, CardCvcElement,
  useStripe, useElements } from '@stripe/react-stripe-js';
import { UserContext } from '../../../context/user-context';
import { OrdersContext } from '../../../context/orders-context';
import { fetchFromAPI, sendMail } from '../../../helpers';

const CustomCheckout = ({ shipping, cartItems, total, clearCart, bkash }) => {
  console.log('cartItems:', cartItems, ', bkash:', bkash);
  const { user } = useContext(UserContext); //console.log("user:", user);
  const { refreshOrders } = useContext(OrdersContext);
  const navigate = useNavigate();
  const [ processing, setProcessing ] = useState(false);
  const [ error, setError ] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [cards, setCards] = useState(null);
  const [payment, setPaymentCard] = useState('');
  const [saveCard, setSaveCard] = useState(false);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  //const stripe = useStripe(), elements = useElements();

  const [pmtMethod, setPmtMethod] = useState('COD');
  if(bkash&& bkash.status==='success'&& pmtMethod!=='bKash') {
    setProcessing(true); setPmtMethod('bKash'); }
  const [orderId, setOrderId] = useState(sessionStorage.getItem('orderId'));
  const pResFmStor = sessionStorage.getItem('place-order'); console.log('orderId:', orderId);
  let pRs = null; if(pResFmStor && pResFmStor!=='{}') {
    pRs = JSON.parse(pResFmStor); sessionStorage.removeItem('place-order');
  } const [pRes, setPRes] = useState(pRs); console.log('pRs:', pRs, ', pRes:', pRes);
  if(pRs && !pRes) { setPRes(pRs); console.log('Setting pRes to pRs:', pRs);
  } else { setTimeout(() => { console.log('Checking for place-order in sessionStorage after 2 sec..');
    const pResFmStor = sessionStorage.getItem('place-order');
    let pRs = null; if(pResFmStor && pResFmStor!=='{}') {
      pRs = JSON.parse(pResFmStor); sessionStorage.removeItem('place-order');
    } if(pRs && !pRes) { setPRes(pRs); console.log('Setting pRes to pRs:', pRs);
    } else { setTimeout(() => { console.log('Checking for place-order in sessionStorage after 5 sec..');
      const pResFmStor = sessionStorage.getItem('place-order');
      let pRs = null; if(pResFmStor && pResFmStor!=='{}') {
        pRs = JSON.parse(pResFmStor); sessionStorage.removeItem('place-order');
      } if(pRs && !pRes) { setPRes(pRs); console.log('Setting pRes to pRs:', pRs);
      }
    }, 5000); } console.log('pResFmStor: ', pResFmStor);
  }, 2000); } console.log('pResFmStor: ', pResFmStor);

  useEffect(() => {
    const items = cartItems.map(item => ({price: item.price, quantity: item.quantity}));
    // if(user) { const savedCards = async () => { try {
    //   const cardsList = await fetchFromAPI('get-payment-methods', {method: 'GET'});
    //   setCards(cardsList);
    // } catch(error) { console.log(error); } }; savedCards(); } //console.log('pmtMethod:', pmtMethod);
    if(shipping && pmtMethod==='CC') {
      const body = { cartItems: items,
        shipping: {name: shipping.name, address: {line1: shipping.address}},
        description: 'payment intent for nomad shop', receipt_email: shipping.email
      };
      const customCheckout = async () => {
        try {
          const {clientSecret, id} = await fetchFromAPI('create-payment-intent', {body});
          setClientSecret(clientSecret); setPaymentIntentId(id);
        } catch(error) { console.log(error); }
      };
      customCheckout();
    }
  }, [shipping, cartItems, pmtMethod]);

  const handleCheckout = async () => { //console.log('handleCheckout');
    // setProcessing(true); //console.log('pmtMethod:', pmtMethod);
    // if(pRes) { console.log('pRes:', pRes); //sessionStorage.removeItem('place-order');
    // }
    //--------------------------------------------------------------------------------------------//
    if(pmtMethod==='COD'||pmtMethod==='bKash') { console.log('Placing order: cartItems:',cartItems);
      const items = cartItems.map(item => ({ id: item.id, title: item.title, price: item.price,
        quantity: item.quantity, unit: item.unit, subTyp: item.subTyp, delDay: item.delDay
      }));
      const id = orderId? orderId: uuidv4(), orDt = new Date();
      const body = { orderId: id, orDt, total, items, pmtMethod, shipping, bkash };
      setProcessing(true); console.log('body:', body);
      //--------------------------------------------------------------//
      let html = `<html>
        <body><div style="box-sizing:border-box;">
          <h1>NEW ORDER</h1>
          <p>CUSTOMER: ${shipping.name}</p>
          <p>ADDRESS: ${shipping.address}</p>
          ${cartItems.map(item => (`
            <p>ITEM: ${item.title}</p>
            <p>QUANTITY: ${item.quantity} ${item.unit}</p>
            <p>DELIVERY: ${item.delDay}</p>
          `))}
        </div></body>
      </html>`; //console.log(html); //return;
      //--------------------------------------------------------------//
      const res = await fetchFromAPI('place-order', {body}); console.log("handleCheckout:: res:", res);
      if(res) {
        if(res.status==='success') { // Send mail
          //--------------------------------------------------------------//
          const content = { //mahbubur.rahman.64@gmail.com, mahbub.bysontech@gmail.com
            email: 'arif@orkobd.com, aic871@gmail.com', subject: 'New Order'
            +' - '+shipping?.name +', '+orDt, html, //cc: shipping?.email
          }; let userEmail = user?.email; //console.log('user:', user);
          if(userEmail[0]==='p'&&userEmail[1]==='_') userEmail = ''; //console.log('userEmail:', userEmail);
          content.bcc = 'mahbubur.rahman.64@gmail.com';
          try { console.log('Sending New Order Mail..');
            const json = await sendMail(content);
            console.log('New Order Mail Sent:'); console.log(json);
          } catch(e) { console.log(e); }
          //--------------------------------------------------------------//
          clearCart(); setOrderId(''); sessionStorage.removeItem('shipping');
          sessionStorage.removeItem('orderId'); refreshOrders(); navigate('/success');
        } else navigate('/order-failed');
    } } else {
      let si; //setup intent
      // check if user has selected to save card
      if(saveCard) {
        // make request to create a setup intent
        si = await fetchFromAPI('save-payment-method'); console.log("handleCheckout:: si:", si);
      }
      // const payload = stripe.confirmCardPayment(clientSecret, {
      //   payment_method: { card: elements.getElement(CardNumberElement) }
      // });
      // if(payload.error) {
        setError(`Payment Failed` //`: ${payload.error.message}`
        );
      // } else {
          if(saveCard & si) {
            // send the customer's card details to be saved with stripe
            // await stripe.confirmCardSetup(si.client_secret, { payment_method: {
            //   card: elements.getElement(CardNumberElement)
            // } })
          }
      //   navigate('/success');
      // }
    }
    //--------------------------------------------------------------------------------------------//
    // setProcessing(false);
  };

  useEffect(() =>{ console.log('useEffect:: pRes:', pRes, ', bkash:', bkash);
    if(pRes) { console.log('pRes:', pRes);
      //const bkTranStat = pRes.bkash?.transactionStatus;
      const bkTranStat = (pRes.bkash?
        (pRes.status==='failed'? 'Insuffucient': pRes.bkash.transactionStatus)
      : ''); console.log('bkTranStat:', bkTranStat);
      if(pRes.status==='success') {
        clearCart(); setOrderId(''); sessionStorage.removeItem('shipping');
        sessionStorage.removeItem('orderId'); refreshOrders();
        navigate('/success' + (bkTranStat? '?bkTranStat='+bkTranStat: ''));
      } else navigate('/order-failed' + (bkTranStat? '?bkTranStat='+bkTranStat: ''));
      setPRes(null);
    } else
    if(bkash&& shipping&& bkash.status==='success') { handleCheckout();
      console.log('Calling handleCheckout:: bkash:', bkash, ', shipping:', shipping);
    }
  }, [pRes]);

  const savedCardCheckout = async () => {
    setProcessing(true);
    // update the payment intent to include the customer parameter
    const { clientSecret } = await fetchFromAPI('update-payment-intent', {
      body: { paymentIntentId }, method: 'PUT'
    });
    //const payload = await stripe.confirmCardPayment(clientSecret, {payment_method: payment});
    //if(payload.error) {
      setError(`Payment Failed`); //`: ${payload.error.message}`);
      setProcessing(false);
    //} else { navigate('/success'); }
  }

  const cardHandleChange = event => {
    const { error } = event; setError(error? error.message: '');
  };
  const cardStyle = { style: {
    base: { color: "#000", fontFamily: 'Roboto, sans-serif',
      fontSmoothing: "antialiased", fontSize: "16px", "::placeholder": { color: "#606060" }
    },
    invalid: { color: "#fa755a", iconColor: "#fa755a" }
  }};

  let cardOption;
  if(cards) {
    cardOption = cards.map(card => {
      const { card: { brand, last4, exp_month, exp_year } } = card;
      return (
        <option key={card.id} value={card.id}>
          { `${brand}/ **** **** **** ${last4} ${exp_month}/${exp_year}` }
        </option>
      );
    });
    cardOption.unshift(
      <option key='Select a card' value=''>Select A Card</option>
    )
  }

  return (
    <div>
      { user && (cards && cards.length>0) && <div>
        <h4>Pay with saved card</h4>
        <select value={payment} onChange={e => setPaymentCard(e.target.value)}>
          { cardOption } </select>
        <button type='submit' className='button is-black nomad-btn submit saved-card-btn'
          disabled={processing||!payment} onClick={() => savedCardCheckout()}>{
          processing? 'PROCESSING': 'PAY WITH SAVED CARD'}
        </button>
      </div> }
      <h4>Enter Payment Details</h4>
      <div className='stripe-card'>{/* <CardNumberElement
        className='card-element' options={cardStyle} onChange={cardHandleChange} /> */}
      </div>
      <div className='stripe-card'>{/* <CardExpiryElement
        className='card-element' options={cardStyle} onChange={cardHandleChange} /> */}
      </div>
      <div className='stripe-card'>{/* <CardCvcElement
        className='card-element' options={cardStyle} onChange={cardHandleChange} /> */}
      </div>
      {user && <div className='pmt-method'>
        <div><input type='radio' id='cod' value='COD' name='pmt-method' checked={pmtMethod==='COD'}
          onChange={e => setPmtMethod(e.target.value)} />
          <label htmlFor='cod'>Cash on Delivery</label>
        </div>
        <div><input type='radio' id='bkash' value='bKash' name='pmt-method' checked={pmtMethod==='bKash'}
          onChange={async e => { setPmtMethod(e.target.value); console.log('e.target.value:', e.target.value);
            // Request Checkout in bKash Playground
            //--------------------------------------------------------------------------------//
            try { const baseUrl = process.env.REACT_APP_BASE_URL_WEB; console.log('baseUrl:', baseUrl);
              setProcessing(true); // 'https://www.orkofarms.com' // 'http://localhost:3000'
              const id = orderId? orderId: uuidv4(); if(!orderId) {
                setOrderId(id); sessionStorage.setItem('orderId', id);
              } sessionStorage.setItem('shipping', JSON.stringify(shipping));
              const body = { total, orderId: id, ref: shipping.phone,
                callbackURL: baseUrl + '/checkout' }; console.log('body:', body);
              const res = await fetchFromAPI('bkash-checkout', { // bkash-callback
                body }); console.log('res:', res);
              if(res&& res.bkashURL) { console.log('Navigating to bkashUrl..');
                if(user) sessionStorage.setItem('user', JSON.stringify(user));
                const bkashUrl = res.bkashURL; console.log('bkashUrl:', bkashUrl);
                window.location.href = bkashUrl; //navigate(bkashUrl);
              } else setProcessing(false);
            } catch(error) { console.log('error:', error);
              setProcessing(false);
            }
            //--------------------------------------------------------------------------------//
          }} />
          <label htmlFor='cod'>bKash merchant a/c 01886200856</label>
        </div>
        <div><input type='radio' id='cc' value='CC' name='pmt-method' checked={pmtMethod==='CC'}
          onChange={e => setPmtMethod(e.target.value)} disabled={true} //false
          /><label htmlFor='cc'>Credit Card</label>
        </div>
      </div>}
      {user && pmtMethod==='CC' && <div className='save-card'><label>Save Card</label>
          <input type='checkbox' checked={saveCard} onChange={e => setSaveCard(e.target.checked)} />
        </div>
      }
      <div className='submit-btn'><button className='button is-black nomad-btn submit' disabled={
        processing} onClick={() => handleCheckout()}>{(processing? 'PROCESSING':
        pmtMethod==='COD'? 'PLACE ORDER': 'PAY')}</button>
      </div>
      {error && (<p className='error-message'>{error}</p>)}
    </div>
  );
};

export default CustomCheckout;
