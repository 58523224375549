const Cow = props => {
  const { id, tagNo, purchDt, bodyWt, age, insurance, uid } = props;
  //const sale = { id, purchDt, qty, value, bodyWt, due, uid };
  const bHead = !(tagNo|| purchDt|| bodyWt|| age); //console.log('dt:', dt, (typeof dt));
  const dt = purchDt? new Date(purchDt): (bHead? 'Purch Date': '');
  let sDt; if((typeof dt)==='object') { sDt =
    `${dt.getDate()}/${dt.getMonth()+1}/${dt.getFullYear().toString().substring(2)}`;
  } else sDt = dt; //console.log('bodyWt:', bodyWt,(typeof bodyWt));
  let sTag = '', sBWt = '', sAge = '', sIns = ''; if(bHead) {
    sTag = 'Tag Number'; sBWt = 'Body Weight'; sAge = 'Age'; sIns = 'Insurance';
  }
  if(tagNo) sTag = `${tagNo.length<5?'0'.repeat(5-tagNo.length):''}${tagNo}`;
  if((typeof bodyWt)==='number') sBWt = `${bodyWt} Kg`;
  if(age) sAge = `${age}`;
  if(insurance) sIns = `${insurance}`;
  return (<>
    <div className='cow'>
      <div className='wide-line'>
        <div className='tag-number'>{!bHead&& <> &nbsp; &nbsp;</>}{sTag}</div>
        <div className='purch-date'>{sDt}</div>
        <div className='bodyWt'>{sBWt}</div>
        <div className='age'>{sAge}</div>
        <div className='due'>{sIns}{!bHead&& <> &nbsp; &nbsp;</>}</div>
      </div>
    </div><hr />
  </>);
};

export default Cow;
