import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
//import { authOld } from '../../firebase'; //, auth
import { UserContext } from '../../context/user-context';
import { fetchFromAPI } from '../../helpers';
import Layout from '../shared/layout';
import '../sign-up/sign-up.styles.scss';

const SignIn = () => {
  const [error, setError] = useState(null);
  const { user, setUser } = useContext(UserContext); //console.log("user:", user);
  const navigate = useNavigate();
  const initialValues = { phone_email: '', password: '' };
  const auth = getAuth();

  // const handleSignIn_Old = async (values, { setSubmitting }) => {
  //   const { email, password } = values;
  //   try {
  //     await authOld.signInWithEmailAndPassword(email, password);
  //     setSubmitting(false); navigate('/shop');
  //   } catch(error) { console.log(error);
  //     setSubmitting(false); setError(error);
  //   }
  // };

  const handleSignIn = async (values, { setSubmitting }) => {
    const { phone_email, password } = values;
    //-------------------------------------------------------------------------------//
    //-------------------------------------------------------------------------------//
    try { let email = '';
      if(phone_email.includes('@')) email = phone_email;
      else { email = 'p_' + phone_email + '@test.com';
        const url = 'get-user-email?phone='+phone_email; console.log('url:', url);
        const res = await fetchFromAPI(url, {method: 'GET'}); console.log('res:', res);
        if(res&& res.email) {
          email = res.email; console.log('email:', email);
        }
        // const phone = '+88' + phone_email; console.log('phone:', phone);
        // const appVerifier = await window.recaptchaVerifier;
        // const cnfmObj = await signInWithPhoneNumber(auth, phone, appVerifier);
        // console.log('cnfmObj:', cnfmObj);
        // if(phone==='+8801234567890' && cnfmObj) { const otp = '123456';
        //   try {
        //     const res = await cnfmObj.confirm(otp); console.log('Success res:', res);
        //     const { phoneNumber } = res.user; console.log('phoneNumber:', phoneNumber);
        //   } catch(e) {
        //     console.log('Otp error:', e);
        //   }
        // }
      }
      // await authOld.signInWithEmailAndPassword(email, password);
      // const auth = getAuth(); //console.log("Sign-in:: auth:", auth);
      // console.log("Sign-in:: auth.currentUser:", auth.currentUser);
      //console.log('email:', email, ', password:', password);
      await signInWithEmailAndPassword(auth, email, password); //if(email)
      //console.log("Signed In. getAuth().currentUser:", getAuth().currentUser);
      //console.log("getAuth().currentUser.displayName:", getAuth().currentUser.displayName);
      if(auth.currentUser) {
        const { uid, displayName, email } = auth.currentUser;
        setUser({ uid, displayName, email });

        const nextPage = sessionStorage.getItem('nextPage'); console.log('nextPage:', nextPage);
        if(nextPage) { sessionStorage.removeItem('nextPage'); navigate(`/${nextPage}`); }
        else navigate('/shop');

      } else setUser(null);
      setSubmitting(false); //navigate('/shop');
    } catch(error) { console.log(error);
      setSubmitting(false); setError(error); setUser(null);
    }
  };

  return (
    <Layout>
      <h1>Sign In</h1>
      <div className='form-container'>
        <Formik initialValues={initialValues}
          onSubmit={handleSignIn //values => {console.log(values)}
          }>
          {({values, handleChange, handleSubmit, isSubmitting}) => {
            return <form onSubmit={handleSubmit}>
              <div><input type='text' name='phone_email' onChange={handleChange} //type='email'
                value={values.phone_email} placeholder='Phone or Email'
                className='nomad-input' /></div>
              <div><input type='password' name='password' onChange={handleChange}
                value={values.password} placeholder='Password'
                className='nomad-input' /></div>
              <div className='submit-btn'><button type='submit' disabled={isSubmitting}
                className='button is-black nomad-btn submit'>Sign In</button></div>
              <div className='error-message'>
                { error && <p>{error.message}</p> }
              </div>
              <div><p>If you don't have an account, please <a href='/sign-up'>Register</a></p></div>
            </form>;
          }}
        </Formik>
      </div>
    </Layout>
  );
};

export default SignIn;
