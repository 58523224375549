import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import { Buffer } from "buffer";
import OrderItem from './order-item';
import { UserContext } from '../../../context/user-context';
import { sendMail, sendSms } from '../../../helpers.js';
import InvoiceDoc, { toHtml } from '../../../components/invoice/invoice-doc.jsx';
import { PlusCircleIcon, CrossCircleIcon, TrashIcon } from '../../icons';
import './cust-order.styles.scss';

const CustOrder = props => { //console.log('window.location:', window.location);
  const { id, orDt, items, total, pmtMethod, pmtStatus, paymentID, pyDt,
    bkshTrxID, refundTrxID, paidAmt, refundAmt, rfDt, shipping, orSt, uid, prepareOrder,
    assignOrder, deliverOrder, refundOrder, reOrder, cancelOrder, reviveOrder, deleteOrder,
    admin, changelHandler, change, increase, decrease, changeSubtyp, changeDelday } = props;
  // Returns a random integer from 0 to 8998: // 1001 to 9999:
  const otp = Math.floor(Math.random() * 8999) + 1001; //console.log('Send SMS:', otp);
  const order = { id, orDt, items, total, pmtMethod, pmtStatus, paymentID, pyDt,
    bkshTrxID, refundTrxID, paidAmt, refundAmt, rfDt, shipping, orSt, otp, uid };
  const funcs = { increase, decrease, changeSubtyp, changeDelday, order };
  const { user } = useContext(UserContext); //console.log("Context user:", user);
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [click, setClick] = useState(false);
  const [ckSms, setCkSms] = useState(false);
  const msg = `Your ORKO OTP is ${order.otp}. Plz tell this number to the Delivery Man.`;
  const dt = new Date(orDt), sDt = dt?
    `${dt.getDate()}/${dt.getMonth()+1}/${dt.getFullYear().toString().substring(2)}`: '';
  //const itemCount = items.reduce((total, item) => total + item.quantity, 0);
  let sPmt = pmtMethod, sPmSt = pmtStatus; switch(pmtMethod) {
    case 'COD': sPmt = 'Cash On Dedivery'; break;
  } const { name, address, phone, email, locatn } = shipping?
    shipping: {name:'', address:'', phone:'', email:'', locatn:''};
  let stat; switch(orSt) {
    case 'A': stat = 'ASSIGNED'; break;
    case 'C': stat = 'CANCELLED'; break;
    case 'D': stat = 'DELIVERED'; break;
    case 'P': stat = 'PREPARING'; break;
    case 'R': stat = 'RE-ORDERED'; break;
    default:  stat = 'PENDING';
  }

  const mailHandler = (e) => {
    console.log('mailHandler:: order:', order);
    setClick(true); //console.log('Testing'); return;
    e.stopPropagation(); if(!order) return false;
    const dt = new Date(order.orDt); console.log('dt:', dt);
    const fileName = 'Invoice_' + `${dt.getFullYear()}${dt.getMonth()}${dt.getDate()}`
    + '_' + order.id.substr(order.id.length-4) + '.pdf'; console.log('fileName:', fileName);
    pdf(<InvoiceDoc order={order} />).toBlob().then(async (blob) => { //console.log("blob:", blob);
      //handleConfirmSendMail(blob, fileName);
      //-------------------------------------//
      const arBuffer = await blob.arrayBuffer(); console.log("mailHandler: arBuffer:", arBuffer);
      const buffer = Buffer.from(arBuffer); console.log("mailHandler: buffer:", buffer);
      const letr = toHtml(order); //console.log("letr:", letr); //background-color:#f4f4f4;
      let html = `<html>
        <head><link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
          <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400;1,700&display=swap"
          rel="stylesheet"><style>
          * {margin:0;padding:0;box-sizing:border-box;}
          body {font-family:Roboto,"Helvetica Neue",Arial,sans-serif;font-size:1rem;line-height:1.5;}
          .big {font-size:36px;color:grey;margin-left:-3.1px;}
          .bold {font-weight:bold;}
          h1 {font-size:20px;font-weight:bold;text-align:center;}
          .img-container {width:70px;height:70px;float:right;}
          img {width:70px;height:70px;text-align:right;}
          .canvas {position:absolute;top:40px;right:45px;
            background-color:rgba(255,255,255,0.2);height:70px;width:70px;}
          .center {text-align:center;}
          .col1 {width:500px;} .col2 {width:300px;} .grey {color:grey;}
          .item {width:260px;} .qty {width:200px;} .rate {width:130px;}
          .line {display:flex;flex-direction:row;}
          .page {padding:48px 48px 0 36px;position:relative;}
          .pb8 {padding-bottom:8px;} .pr12 {padding-right:12px;}
          .size10 {font-size:10px;} .size12 {font-size:12px;}
          .size14 {font-size:14px;padding-bottom:8px;}
          .right {text-align:right;}
          p span {display:inline-block;vertical-align:middle;}
        </style></head>
        <body><div style="box-sizing:border-box;">
          `; html+=letr; html+=`
        </div></body>
      </html>`; //console.log(html); /*position:absolute;bottom:40px;left:56px;*/
      const content = { //mahbubur.rahman.64@gmail.com, mahbub.bysontech@gmail.com
        email: order.shipping?.email, subject: fileName.substring(0,fileName.length-4)
        +' - '+order.shipping?.name, html, fileName, buffer, cc: 'arif@orkobd.com'
      }; let userEmail = user?.email; console.log('user:', user);
      if(userEmail[0]==='p'&&userEmail[1]==='_') userEmail = ''; console.log('userEmail:', userEmail);
      content.bcc = 'mahbubur.rahman.64@gmail.com'+(userEmail?', '+userEmail:'');
      //-------------------------------------//
      sendMail(content).then((json) => { console.log("Mail sent.");
        console.log("sendMail:: json:", json);
        navigate('/mail-sent?sentStat=true');
        // setInputMode(0); setTimeout(() => { autoRef.current?.focus();
        //   setAlertMesg("Email sent to address: "+formValues.email); setBtn1Text("OK");
        //   btn1Handler = handleAlertClick; setBtn2Text(""); setAlertOpen(true);
        // }, 100);
      }).catch(error => { console.log("Mail could NOT be sent.");
        console.log("sendMail:: error:", error);
        navigate('/mail-sent?sentStat=false');
      });
      //-------------------------------------//
    });
  };

  const smsHandler = (e) => { console.log('smsHandler:: order:', order);
    e.stopPropagation(); if(!order) return false; setCkSms(true);
    console.log('Send SMS:', order.shipping.phone, msg);
    //-------------------------------------//
    sendSms(order.shipping.phone, msg).then((json) => { console.log("SMS sent.");
      setCkSms(false); console.log("sendSms:: json:", json);
    }).catch(error => { console.log("SMS could NOT be sent.");
      setCkSms(false); console.log("sendSms:: error:", error);
    });
    //-------------------------------------//
  };

  return (<>
    <div className='cust-order'>
      <div className='hide-in-desktop' style={{marginTop:'10px'}}>
        <div className='order-date'>Date: {sDt}</div>
        <div>Name: <span className='name'>{`${name}`}</span></div>
        <div>Address: {`${address}, ${locatn}`}</div><br />
        <div>Order Details:</div>
        <div style={{display:'flex'}}>
          <div style={{flex:2}}>Item</div>
          <div style={{flex:1}}>Quantity</div>
          <div style={{flex:1}}>Rate</div>
          <div style={{flex:1}}>Total</div>
        </div>
      </div>
      <div className='wide-line hide-in-mobile'>
        <div className='order-date'>{sDt}</div>
        {/* <div className='item-count'>{itemCount} item{itemCount===1?'':'s'}</div> */}
        <div className='location'>{locatn}</div>
        <div className='order-total'><h4>Tk {total}/-</h4></div>
        <div className='pmt-method'>{sPmt}
          <div className='pmt-status'>{sPmSt}
            <div className='pmt-btns'>{sPmSt==='Paid'&& orSt!=='D'&& user&& user.type==='A'&& paidAmt?
              <button className='button btn-refund' onClick={()=>{
                setProcessing(true); refundOrder(order); //setProcessing(false);
              }} disabled={processing}>REFUND</button>: null}
              {user&& user.type!=='D'&& <button className='button btn-invoice' onClick={()=>{
                //console.log('process.env.REACT_APP_BASE_URL_WEB:', process.env.REACT_APP_BASE_URL_WEB);
                //console.log('window.location.origin', window.location.origin);
                //window.open(process.env.REACT_APP_BASE_URL_WEB+'/invoice/'+id, '_blank'); //http://localhost:3000
                window.open(window.location.origin+'/invoice/'+id, '_blank'); // http://localhost:3000
                //style={{backgroundColor:'rgb(200,200,250)'}}
              }}>INVOICE</button>}
              {user&& user.type==='D'&& <button className='button btn-sms' onClick={smsHandler}
                disabled={ckSms}>SEND SMS</button>}
            </div>
          </div>
        </div>
        <div className={`ord-status ${stat.toLowerCase()}`}>{stat}</div>
      </div>
      <div className='wide-line'>
        <div className='items-container'>
          {
            items.map((item, i) => <OrderItem {...item} i={i} change={change} {...funcs} key={item.id} />)
          }
        </div>
        <div className='shipping-container hide-mobi-block'>
          <div className='shipping'><span className='name'>{`${name}`}</span></div>
          <div className='shipping'>{`${address}, ${locatn}`}</div>
          {email&& <div className='shipping'>Email: {email}</div>}
          <div className='shipping'>Phone: <b>{phone}</b></div>
          {/* <div className='shipping'>{<span>Location: <b>{locatn}</b></span>}</div> */}
        </div>
        <div className='btns-container'>
          {user && user.type!=='D' && <>{
            orSt==='C'? <>
              <button className='button is-white btn-revive' onClick={()=>{
                reviveOrder(order);
              }}>REVIVE</button>
              <button className='button btn-trash' onClick={() => deleteOrder(order)}>DELETE</button>
            </>: orSt==='D'? <>
              <button className='button is-white btn-reorder' onClick={()=>reOrder(order)}>RE-ORDER</button>
              <button className='button btn-trash' onClick={() => deleteOrder(order)}>DELETE</button>
            </>: <>
              <button className='button is-white btn-change' onClick={changelHandler}>CHANGE</button>
              { admin && ( !orSt||
                orSt==='R'? <button className='button is-white btn-prepare' onClick={()=>prepareOrder(order)}>PREPARE</button>
                :orSt==='P'? <button className='button is-white btn-assign' onClick={()=>assignOrder(order)}>ASSIGN</button>
                :orSt==='A'? <button className='button is-white btn-deliver' onClick={()=>deliverOrder(order)}>DELIVER</button>
                : null )
              }
              <button className='button is-black btn-cancel' onClick={() => cancelOrder(order)}>CANCEL</button>
            </>
          }</>}
          {/* <button title='Cancel' className='btn-cancel' onClick={cancelHandler}><CrossCircleIcon width='20px' /></button> */}
        </div>
      </div>
      <div className='hide-in-desktop'><br />
        <div>Mode:</div>
        <div className='pmt-method'><b>{sPmt}</b>
          <div className='pmt-status'>{sPmSt}
            <div className='pmt-btns' style={{marginTop:'10px',marginLeft:'17vw' //40vw
            , display:'flex'}}>{sPmSt==='Paid'&& orSt!=='D'&& user&& user.type==='A'&& paidAmt?
              <button className='button btn-refund' onClick={()=>{
                setProcessing(true); refundOrder(order); //setProcessing(false);
              }} disabled={processing}>REFUND</button>: null}
              {user&& user.type!=='D'&& <button className='button btn-invoice' onClick={()=>{
                //console.log('process.env.REACT_APP_BASE_URL_WEB:', process.env.REACT_APP_BASE_URL_WEB);
                //console.log('window.location.origin', window.location.origin);
                //window.open(process.env.REACT_APP_BASE_URL_WEB+'/invoice/'+id, '_blank'); //http://localhost:3000
                window.open(window.location.origin+'/invoice/'+id); //, '_blank' http://localhost:3000
                //style={{backgroundColor:'rgb(200,200,250)', fontWeight:'bold'}}
              }}>INVOICE</button>}
              {user&& user.type==='D'&& <button className='button btn-sms' onClick={smsHandler}
                disabled={ckSms}>SEND SMS</button>}
              {order&& order.shipping?.email&& user&& user.type==='A'? <button className='button'
                disabled={click} onClick={mailHandler} style={{ display:'inline-block'
                , marginLeft:'5px', backgroundColor:'#ccc' }}
              >SEND BY EMAIL</button>: null}
            </div>
          </div>
        </div>
      </div>
    </div><hr />
  </>);
};

export default CustOrder;
