import { useContext } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { UserContext } from './context/user-context';
//import { auth } from './firebase';

//export const checkUser - Not possible

//console.log('REACT_APP_BASE_URL_API:', process.env.REACT_APP_BASE_URL_API);
//const API = 'http://localhost:8080'; //'https://orkofarms.com';
export async function fetchFromAPI(endpoint, opts) {
  //const { setUser } = useContext(UserContext);
  const { method, body } = { method: 'POST', body: null, ...opts };
  const auth = getAuth(); //console.log('REACT_APP_BASE_URL_API:', process.env.REACT_APP_BASE_URL_API);
  const user = auth.currentUser; //console.log('endpoint:', endpoint, ', user:', user);
  const token = user && (await user.getIdToken()); //console.log('fetchFromAPI:: token:', token);
  //if(token) {
  const url = `${process.env.REACT_APP_BASE_URL_API}/${endpoint}`, options = {
    method, ...(body && { body: JSON.stringify(body) }),
    headers: {
      'Content-Type': 'application/json', Authorization: `Bearer ${token}`
  } }; //console.log('url:', url, ', options:', options);
  try {
    const res = await fetch(url, options); console.log('res:', res);
    if(res.status===200||res.status===201) return res.json();
    else throw new Error(res.statusText);
  } catch(error) { console.log('error:', error);
    const retryToken = async () => { const auth = getAuth();
      const user = auth.currentUser; console.log('Retry endpoint:', endpoint, ', user:', user);
      const token = user && (await user.getIdToken()); //console.log('Retry:: token:', token);
      if(token) { //console.log('Retry with token');
        const url = `${process.env.REACT_APP_BASE_URL_API}/${endpoint}`, options = {
          method, ...(body && { body: JSON.stringify(body) }),
          headers: {
            'Content-Type': 'application/json', Authorization: `Bearer ${token}`
        } }; console.log('url:', url, ', options:', options);
        try {
          const res = await fetch(url, options); console.log('res:', res);
          if(res.status===200||res.status===201) { const resJsn = await res.json();
            sessionStorage.setItem(endpoint, JSON.stringify(resJsn)); console.log('resJsn:', resJsn);
            console.log('Setting sessionStorage:', endpoint, '-', sessionStorage.getItem(endpoint));
            return resJsn; //res.json();
          } else { //throw new Error(res.statusText);
            console.log('error:', res.statusText); return false;
          }
        } catch(error) { console.log('error:', error); return false; }
      } else return false;
    };
    const forceSignOut = async () => { console.log('Forcing Sign Out!');
      await signOut(auth); sessionStorage.removeItem('user'); //setUser(null);
      sessionStorage.setItem('signedOut', 'true');
    }
    setTimeout(() => {
      const retVal = retryToken().then(rtVal => { console.log('rtVal:', rtVal);
        if(!rtVal) { setTimeout(() => { retryToken().then(rtVal => { console.log('rtVal:', rtVal);
          if(!rtVal) { setTimeout(() => { retryToken().then(rtVal => { console.log('rtVal:', rtVal);

            if(!rtVal) { setTimeout(() => { retryToken().then(rtVal => { console.log('rtVal:', rtVal);
              if(!rtVal) forceSignOut();
            }); }, 2900); }

          }); }, 1900); }
        }); }, 900); }
      });
      // if(!retryToken()) setTimeout(() => {
      //   if(!retryToken()) forceSignOut();
      // }, 900);
    }, 900);
  }
  //} else { console.log('No token'); throw new Error('No token'); }
};

export const isInCart = (product, cartItems) => {
  return cartItems.find(item => item.id === product.id);
};

//----------------------------------------------------------------------------------------//

export const sendMail = async (objMail) => { //: {email:string, subject:string, html:string}
  console.log('sendMail:: objMail:', objMail);
  return fetch(`${process.env.REACT_APP_BASE_URL_API}/send-mail`, { //mode: 'no-cors',
    credentials: "include", statusCode: 200,
    method: 'POST', body: JSON.stringify(objMail),
    headers: {
      "Access-Control-Allow-Origin": "*",
      'Content-Type': 'application/json',
      "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH"
    }
  }).then(response => { //console.log("response:", response);
    //return response; //.json();
    return response.json();
  });
};

//----------------------------------------------------------------------------------------//

export const sendSms = async (phone, msg) => { //: {email:string, subject:string, html:string}
  const objSms = { phone, msg }; console.log('sendSms:: objSms:', objSms);
  return fetch(`${process.env.REACT_APP_BASE_URL_API}/send-sms`, { //mode: 'no-cors',
    credentials: "include", statusCode: 200,
    method: 'POST', body: JSON.stringify(objSms),
    headers: {
      "Access-Control-Allow-Origin": "*",
      'Content-Type': 'application/json',
      "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH"
    }
  }).then(response => { //console.log("response:", response);
    //return response; //.json();
    return response.json();
  });
};

//----------------------------------------------------------------------------------------//
