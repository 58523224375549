import { useContext, useState } from 'react';
import { OrdersContext } from '../../../context/orders-context';
import { UserContext } from '../../../context/user-context';
import Layout from '../../shared/layout';
import CustOrder from './cust-order';
import './orders-page.styles.scss';

const OrdersPage = () => {
  const [ change, setChange ] = useState([]);
  const changelHandler = (i) => { console.log('changelHandler:: change:', change, ', i:', i);
    setChange(oldVal => { const newVal = [...oldVal];
      newVal[i] = !(newVal[i]); return newVal;
    });
  };
  const { orders, prepareOrder, assignOrder, deliverOrder, cancelOrder, reviveOrder, deleteOrder,
    reOrder, increase, decrease } = useContext(OrdersContext);
  const funcs = { prepareOrder, assignOrder, deliverOrder, cancelOrder, reviveOrder, deleteOrder,
    reOrder, increase, decrease };
  const { user } = useContext(UserContext); //console.log('OrdersPage:: orders:', orders, ', user:', user);
  return (
    <Layout>
      <div className='orders-page'>
        <h1>Orders</h1>
        <div className='order-container'>
          {
            orders.map((order, i) => <CustOrder {...order} {...funcs}
            change={change[i]} changelHandler={changelHandler.bind(null,i)}
            admin={['A','M','E'].includes(user.type)} key={order.id} />)
          }
        </div>
      </div>
    </Layout>
  );
};

export default OrdersPage;
