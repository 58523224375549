import { createContext, useState, useEffect } from 'react';
import getShopData from '../shop'; // SHOP_DATA,

export const ProductsContext = createContext();

const ProductsContextProvider = ({ children }) => {
  const [products, setProducts] = useState([]); //SHOP_DATA
  useEffect(() => { getShopData()
    .then(shopData => { setProducts(shopData); //console.log("shopData:", shopData);
    }).catch(err => console.log(err));
  }, []);
  return (
    <ProductsContext.Provider value={{products, activeProducts:
      products.filter(product => product.active)}}>{children}
    </ProductsContext.Provider>
  );
};

export default ProductsContextProvider;
