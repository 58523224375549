import { fetchFromAPI } from '../helpers';

/*
const SHOP_DATA = [
  {
    id: 1,
    title: 'Organic Beef', //'Studio Bag',
    description: 'Our cows are fed with natural and hygenic food.',
    //'The Studio Bag is identical to its predecessor plus a few more inches to love. Transition it from satchel to backpack to crossbody simply by changing straps. Carry it wherever you go - it will be ready to adapt to your needs and keep up with your lifestyle.',
    imageUrl: 'https://storage.googleapis.com/organic-orkobd/images/agro_2.jpg',
    price: 800, unit: 'Kg',
  },
  {
    id: 2,
    title: 'Organic Milk', //'Cumulus',
    description: 'Pure country cow milk',
    //'Light as air. The Cumulus is encased in cloud-like waterproof nylon and has a spacious main interior. Collapse it for easy travel and snap it shut with magnetic closures.',
    imageUrl: 'https://storage.googleapis.com/organic-orkobd/images/cow-milk-1.jpg',
    price: 95, unit: 'Litr',
  },
  {
    id: 3,
    title: 'Deshi Chickens', //'Dance Bag Nylon',
    description: 'Naturally raised organic deshi chicken',
    //'For days when you just need to grab and go. The Dance Bag is a compact, gumdrop-shaped backpack wrapped in water-repellent nylon. It’s the perfect carry-all for your life in motion.',
    imageUrl: 'https://storage.googleapis.com/organic-orkobd/images/desi-chicken-1.webp',
    price: 400, unit: 'Kg',
  },
  {
    id: 4,
    title: 'Deshi Chicken Eggs', //'Stratus Backpack',
    description: 'Organic nutrient healthy chicken eggs',
    //'The Stratus is encased in cloud-like waterproof nylon and features exterior + interior pockets for your water bottle, umbrella, and laptop. Reach around to the side for on-the-go access to the main compartment. Collapse it for easy travel. Your backpack just got an upgrade.',
    imageUrl: 'https://storage.googleapis.com/organic-orkobd/images/desi-eggs-1.jpg',
    price: 160, unit: 'Dozen',
  },
  {
    id: 5,
    title: 'Organic Ducks', //'Cirrus',
    description: 'Naturally raised organic ducks',
    //'The Cirrus rucksack is encased in cloud-like waterproof nylon and features ample interior and exterior pockets, including built-in phone + battery pockets to stay powered up on the go. Luggage slip and secure passport sleeve make it the perfect travel companion. Adventure away.',
    imageUrl: 'https://storage.googleapis.com/organic-orkobd/images/ducks-1.jpg',
    price: 450, unit: 'Kg',
  },
  {
    id: 6,
    title: 'Organic Duck Eggs', //'Mini Circle',
    description: 'Organic nutrient healthy duck eggs',
    //'The Mini Circle is the ultimate multipurpose grab and go style. Transfer it from crossbody to waistbelt to wristlet simply by changing straps. Internal built-in battery that can charge your USB device. Vegan friendly.',
    imageUrl: 'https://storage.googleapis.com/organic-orkobd/images/duck-eggs-1.jpg',
    price: 150, unit: 'Dozen',
  },
  {
    id: 7,
    title: 'Organic Mutton', //'Studio Bag Vaqueta',
    description: 'Our goats are fed with natural and hygenic food.',
    //'You’re not any one thing, why should your bag be? The Studio Bag Vaqueta features everything you know and love about the Studio Bag wrapped up in colorful Italian nylon and exquisite vaqueta leather. ',
    imageUrl: 'https://storage.googleapis.com/organic-orkobd/images/goats-1.jpg',
    price: 1000, unit: 'Kg',
  },
  // {
  //   id: 8,
  //   title: 'Sling',
  //   description: 'The Sling is encased in cloud-like waterproof nylon, features eight pockets, and can be worn as a waistbag or crossbody sling. Five colors, multiple carrying modes, all you. Vegan friendly + made for everyone.',
  //   imageUrl: 'https://i.ibb.co/LzyPnF3/sling.png',
  //   price: 25, unit: '',
  // },
];
*/

const getShopData = async () => { let shopData = [];
  try {
    const res = await fetchFromAPI('get-shop-data', {method: 'GET'});
    //console.log("getData:: res.data:", res.data);
    if(res) shopData = res.data; //console.log('shopData:', shopData);
  } catch(error) { console.log(error); }
  return shopData;
};

export default getShopData; // SHOP_DATA;