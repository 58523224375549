import { useEffect } from 'react'; //useContext,
// import { CartContext } from '../../../context/cart-context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Layout from '../../shared/layout';

const BkashCallback = () => { console.log('BkashCallback');
  // const { clearCart, cartItems } = useContext(CartContext);
  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();
  const status = searchParams.get('status');
  const paymentID = searchParams.get('paymentID');
  console.log('status:', status, ', paymentID:', paymentID);

  useEffect(() => { console.log('BkashCallback:: useEffect');

  }, []);
  // useEffect(() => {
  //   //if(cartItems.length>0) clearCart();
  // }, [clearCart, cartItems]);
  return (
    <Layout>
      <div className='checkout'>
        <h1>Thank you for your order</h1>
        {/* <p>We are currently processing your order and will send you a confirmation email shortly</p> */}
        <p>We are currently processing your order and will contact you soon</p>
        <div>
          <button className='button is-black nomad-btn submit'
            onClick={() => navigate('/shop')}>Continue Shopping</button>
        </div>
      </div>
    </Layout>
  );
};

export default BkashCallback;
