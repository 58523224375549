const months = ['January','February','March','April','May','June'
  ,'July','August','September','October','November','December'];
const days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
const ScheduleLine = props => {
  const { bHead, bSubHd1, bSubHd2, unit, dt, tot, orCust, orLine } = props;
  let cDly, cDNm, sDly, cWkly, cWNm, sWkly, cOne, cONm, sOne, sTot //, sDt;
  if(bHead) {
    sDly = 'Daily'; sWkly = 'Weekly'; sOne = "One Off"; sTot = ''; //sDt = 'Date';
  } else if(bSubHd1) {
    cDly = 'Customer'; sDly = 'Volume'; cWkly = 'Customer'; sWkly = 'Volume';
    cOne = 'Customer'; sOne = "Volume"; sTot = 'Total';
  } else if(bSubHd2) {
    cDly = '#'; cWkly = '#'; //sDly = `(${unit})`; sWkly = `(${unit})`;
    cOne = '#'; //sOne = `(${unit})`; //liter
  } else {
    //if(dt) sDt = `${months[dt.getMonth()]} ${dt.getDate()}, ${dt.getFullYear()}`; //dt.toString();
    if(tot) { sTot = `${tot}`; if(!sOne) sOne = 'Total:'; }
    else if(orLine) { let nTot =0;
      if(orLine.orDly) { sDly = `${orLine.orDly} ${unit}`; cDly = orLine.phD;
        cDNm = orLine.nmD; nTot += parseFloat(sDly); }
      if(orLine.orWkly) { sWkly = `${orLine.orWkly} ${unit}`; cWkly = orLine.phW;
        cWNm = orLine.nmD; nTot += parseFloat(sWkly); }
      if(orLine.orOne) { sOne = `${orLine.orOne} ${unit}`; cOne = orLine.phO;
        cONm = orLine.nmO; nTot += parseFloat(sOne); }
      if(nTot) sTot = `${nTot}`; //console.log('sTot:', sTot);
    } else if(orCust) { let nTot =0;
      if(orCust.orDly) { sDly = orCust.orDly; cDly = orCust.phone; cDNm = orCust.name;
        nTot += parseFloat(sDly); }
      if(orCust.orWkly) { sWkly = orCust.orWkly; cWkly = orCust.phone; cWNm = orCust.name;
        nTot += parseFloat(sWkly); }
      if(orCust.orOne) { sOne = orCust.orOne; cOne = orCust.phone; cONm = orCust.name;
        nTot += parseFloat(sOne); }
      if(nTot) sTot = `${nTot}`; //console.log('sTot:', sTot);
    }
  }
  return (<>
    <div className='delv-schd'>
      <div className={'wide-line'+(bHead||bSubHd1||bSubHd2?' hide-in-mobile':'')}>
        <div className={'date'+(tot?' hide-in-mobile':'')}
          style={{flex: 0.5}}>{/*sDt*/}</div>{/*flex:(!(cDly||cWkly||cOne)?1.9:1)*/}
        <div className={tot?'hide-in-mobile':'flex'} style={{flex: 1.5}}>{/*, display:'flex'*/}
          {cDly&& <div className='daily' style={{flex:1}}>{/*1*/}
            {cDly}{cDNm&& <><br />{cDNm}</>}</div>}
          <div className='daily' style={{flex:(cDly?1:1),textAlign:'center',paddingRight:(bHead?'3vw':0)}}>{sDly}</div>{/*1:2.4*/}
        </div>
        <div className={tot?'hide-in-mobile':'flex'} style={{flex: 1.5}}>{/*, display:'flex'*/}
          {cWkly&& <div className='weekly' style={{flex:1}}>
            {cWkly}{cWNm&& <><br />{cWNm}</>}</div>}
          <div className='weekly' style={{flex:(cWkly?1:1),textAlign:'center',paddingRight:(bHead?'3vw':0)}}>{sWkly}</div>{/*1:2.3*/}
        </div>
        <div className={tot?'total-div inblock-in-mobile':'flex'} style={{flex: 1.5}}>{/*, display:'flex'*/}
          {cOne&& <div className='one-off' style={{flex:1.5}}>
            {cOne}{cONm&& <><br />{cONm}</>}</div>}
          <div className='one-off' style={{flex:1,textAlign:(tot?'right':'center')
            , paddingRight:(bHead||tot?'3vw':0)}}>{sOne}</div>
        </div>
        <div className={'total'+(orLine?' hide-in-mobile':(tot?' inblock-in-mobile':''))}
          style={{flex:1, paddingLeft: '5vw'}}>
          {bSubHd1|| !unit? sTot: <b>{sTot}&nbsp;</b>}
          {sTot&& unit&& <> {unit}</>}{!sTot&& <>&nbsp; &nbsp;</>}
        </div>
      </div>
    </div>
  </>);
}

const DeliverySchedule = props => {
  const { orders } = props, dt = new Date(); let orDates = [], orPrds = []; //console.log('orders:', orders);
  const d1 = new Date(dt); d1.setDate(d1.getDate() + 1);
  const d2 = new Date(dt); d2.setDate(d2.getDate() + 2);
  const dates =[ dt, d1, d2 ]; //console.log('dates:', dates);
  for(const or of orders) { if(or.orSt!=='C') { //console.log('or:', or);
    for(const itm of or.items) { //console.log('itm:', itm);
      //------------------------------------------------------------------------------------------//
      for(const d of dates) {
        let orDate = orDates.find(orDt => orDt.dt.getDate()===d.getDate()&&
          orDt.dt.getMonth()===d.getMonth()&& orDt.dt.getFullYear()===d.getFullYear()
          ); if(!orDate) { orDate = { dt:d, orQty:0, orPrds:[] }; orDates.push(orDate);
        }
        let prd = orDate.orPrds.find(pr => pr.id===itm.id
          ); if(!prd) { prd = { id:itm.id, title:itm.title, price:itm.price,
          unit:itm.unit, orQty:0, orLines:[] }; orDate.orPrds.push(prd);
        }
        if(itm.subTyp==='Daily') { const delDay = new Date(itm.delDay); //console.log('delDay:', delDay);
          if(d > delDay) { //console.log('Daily quantity:', itm.quantity);
            prd.orQty += itm.quantity; orDate.orQty += itm.quantity;
            let orLine = prd.orLines.find(orLn => orLn.uiD===''); if(!orLine) { orLine = {
              uiD:'', phD:'', nmD:'', uiW:'', phW:'', nmW:'', uiO:'', phO:'', nmO:'',
              orDly:0, orWkly:0, orOne:0 }; prd.orLines.push(orLine);
            } orLine.orDly += itm.quantity;
            orLine.uiD = or.uid; orLine.phD = or.shipping.phone; orLine.nmD = or.shipping.name;
        } }
        if(itm.subTyp==='Weekly') { const delDay = new Date(d); //console.log('itm.delDay:', itm.delDay);
          const dtWkDay = d.getDay(), delWkDay = days.findIndex(day => day===itm.delDay);
          let addDay = 0; if(dtWkDay<delWkDay) addDay = delWkDay - dtWkDay;
          else if(dtWkDay>delWkDay) addDay = delWkDay + 7 - dtWkDay;
          //console.log('dtWkDay:', dtWkDay, ', delWkDay:', delWkDay, ', addDay:', addDay);
          if(addDay>0) delDay.setDate(delDay.getDate()+addDay); //console.log('delDay:', delDay);
          if(d.getDate()===delDay.getDate()&& d.getMonth()===delDay.getMonth()&&
            d.getFullYear()===delDay.getFullYear()) { //console.log('Weekly quantity:', itm.quantity);
            prd.orQty += itm.quantity; orDate.orQty += itm.quantity;
            let orLine = prd.orLines.find(orLn => orLn.uiW===''); if(!orLine) { orLine = {
              uiD:'', phD:'', nmD:'', uiW:'', phW:'', nmW:'', uiO:'', phO:'', nmO:'',
              orDly:0, orWkly:0, orOne:0 }; prd.orLines.push(orLine);
            } orLine.orWkly += itm.quantity;
            orLine.uiW = or.uid; orLine.phW = or.shipping.phone; orLine.nmW = or.shipping.name;
        } }
        if(itm.subTyp==='One off') { const delDay = new Date(itm.delDay); //console.log('delDay:', delDay);
          if(d.getDate()===delDay.getDate()&& d.getMonth()===delDay.getMonth()&&
            d.getFullYear()===delDay.getFullYear()) { //console.log('One off quantity:', itm.quantity);
            prd.orQty += itm.quantity; orDate.orQty += itm.quantity;
            let orLine = prd.orLines.find(orLn => orLn.uiO===''); if(!orLine) { orLine = {
              uiD:'', phD:'', nmD:'', uiW:'', phW:'', nmW:'', uiO:'', phO:'', nmO:'',
              orDly:0, orWkly:0, orOne:0 }; prd.orLines.push(orLine);
            } orLine.orOne += itm.quantity; //console.log('or:', or);
            orLine.uiO = or.uid; orLine.phO = or.shipping.phone; orLine.nmO = or.shipping.name;
        } }
      }
      //------------------------------------------------------------------------------------------//
      //------------------------------------------------------------------------------------------//
      // let prd = orPrds.find(pr => pr.id===itm.id //&& pr.dt && ( pr.dt.getDate()===dt.getDate()&&
      //   //pr.dt.getMonth()===dt.getMonth()&& pr.dt.getFullYear()===dt.getFullYear())
      //   ); if(!prd) { prd = { id:itm.id, title:itm.title, price:itm.price,
      //   unit:itm.unit, orQty:0, orDates:[] }; orPrds.push(prd);
      // }
      // for(const d of dates) {
      //   let orDate = prd.orDates.find(orDt => orDt.dt.getDate()===d.getDate()&&
      //   orDt.dt.getMonth()===d.getMonth()&& orDt.dt.getFullYear()===d.getFullYear());
      //   if(!orDate) { orDate = { dt:d, orQty:0, orCusts:[], orLines:[] }; prd.orDates.push(orDate); }
      //   let orCust = orDate.orCusts.find(orCs => orCs.uid===or.uid); if(!orCust) {
      //     orCust = { uid:or.uid, phone:or.shipping.phone, name:or.shipping.name,
      //     orDly:0, orWkly:0, orOne:0 }; orDate.orCusts.push(orCust);
      //   } //console.log('itm.subTyp:', itm.subTyp, ', itm.delDay:', itm.delDay);
      //   if(itm.subTyp==='Daily') { const delDay = new Date(itm.delDay); //console.log('delDay:', delDay);
      //     if(d > delDay) { //console.log('Daily quantity:', itm.quantity);
      //       orCust.orDly += itm.quantity; prd.orQty += itm.quantity; orDate.orQty += itm.quantity;
      //       let orLine = orDate.orLines.find(orLn => orLn.uiD===''); if(!orLine) { orLine = {
      //         uiD:'', phD:'', nmD:'', uiW:'', phW:'', nmW:'', uiO:'', phO:'', nmO:'',
      //         orDly:0, orWkly:0, orOne:0 }; orDate.orLines.push(orLine);
      //       } orLine.orDly += itm.quantity;
      //       orLine.uiD = or.uid; orLine.phD = or.shipping.phone; orLine.nmD = or.shipping.name;
      //   } }
      //   if(itm.subTyp==='Weekly') { const delDay = new Date(d); //console.log('itm.delDay:', itm.delDay);
      //     const dtWkDay = d.getDay(), delWkDay = days.findIndex(day => day===itm.delDay);
      //     let addDay = 0; if(dtWkDay<delWkDay) addDay = delWkDay - dtWkDay;
      //     else if(dtWkDay>delWkDay) addDay = delWkDay + 7 - dtWkDay;
      //     //console.log('dtWkDay:', dtWkDay, ', delWkDay:', delWkDay, ', addDay:', addDay);
      //     if(addDay>0) delDay.setDate(delDay.getDate()+addDay); //console.log('delDay:', delDay);
      //     if(d.getDate()===delDay.getDate()&& d.getMonth()===delDay.getMonth()&&
      //       d.getFullYear()===delDay.getFullYear()) { //console.log('Weekly quantity:', itm.quantity);
      //       orCust.orWkly += itm.quantity; prd.orQty += itm.quantity; orDate.orQty += itm.quantity;
      //       let orLine = orDate.orLines.find(orLn => orLn.uiW===''); if(!orLine) { orLine = {
      //         uiD:'', phD:'', nmD:'', uiW:'', phW:'', nmW:'', uiO:'', phO:'', nmO:'',
      //         orDly:0, orWkly:0, orOne:0 }; orDate.orLines.push(orLine);
      //       } orLine.orWkly += itm.quantity;
      //       orLine.uiW = or.uid; orLine.phW = or.shipping.phone; orLine.nmW = or.shipping.name;
      //   } }
      //   if(itm.subTyp==='One off') { const delDay = new Date(itm.delDay); //console.log('delDay:', delDay);
      //     if(d.getDate()===delDay.getDate()&& d.getMonth()===delDay.getMonth()&&
      //       d.getFullYear()===delDay.getFullYear()) { //console.log('One off quantity:', itm.quantity);
      //       orCust.orOne += itm.quantity; prd.orQty += itm.quantity; orDate.orQty += itm.quantity;
      //       let orLine = orDate.orLines.find(orLn => orLn.uiO===''); if(!orLine) { orLine = {
      //         uiD:'', phD:'', nmD:'', uiW:'', phW:'', nmW:'', uiO:'', phO:'', nmO:'',
      //         orDly:0, orWkly:0, orOne:0 }; orDate.orLines.push(orLine);
      //       } orLine.orOne += itm.quantity; //console.log('or:', or);
      //       orLine.uiO = or.uid; orLine.phO = or.shipping.phone; orLine.nmO = or.shipping.name;
      //   } }
      // }
      //------------------------------------------------------------------------------------------//
  } } } orPrds.sort((p1,p2) => parseInt(p1.id)-parseInt(p2.id));
  for(const orDt of orDates) { orDt.orPrds.sort((p1,p2) => parseInt(p1.id)-parseInt(p2.id)); }
  //console.log('orPrds:', orPrds, ', orDates:', orDates);
  return <>
    {orDates.map(orDate => <div key={orDate.dt}>
      <h3>{`${months[orDate.dt.getMonth()]} ${orDate.dt.getDate()}, ${orDate.dt.getFullYear()}`}</h3>
      {orDate.orPrds.map(orPrd => orPrd.orQty? <div key={orPrd.id}>
        <h4>{orPrd.title}</h4>
        <ScheduleLine bHead={true} />
        <ScheduleLine bSubHd1={true} />
        <ScheduleLine bSubHd2={true} unit={orPrd.unit} />
        {(//console.log('orDate:', orDate),
          orPrd.orLines.filter(l => l.orDly||l.orWkly||l.orOne)
          .map((orLine,i) => <div key={i}>
            <ScheduleLine orLine={orLine} unit={orPrd.unit} />
          </div>)
        )}
        <ScheduleLine tot={orPrd.orQty} unit={orPrd.unit} />
      </div>: null)}
    </div>)}
    {/* //------------------------------------------------------------------------------// */}
    {/* {orPrds.map(orPrd => orPrd.orQty? (<div key={orPrd.id}>
      <h2>{orPrd.title}</h2>
      <ScheduleLine bHead={true} />
      <ScheduleLine bSubHd1={true} />
      <ScheduleLine bSubHd2={true} unit={orPrd.unit} />
      <h4>{`${months[dt.getMonth()]} ${dt.getDate()}, ${dt.getFullYear()}`}</h4>
      { orPrd.orDates.filter(orDate => orDate.dt.getDate()===dt.getDate())
        .map(orDate => <div key={orDate.dt}>
          { orDate.orCusts.filter(c => c.orDly||c.orWkly||c.orOne)
            .map(orCust => <div key={orCust.uid}>
              <ScheduleLine orCust={orCust} />
              <ScheduleLine tot={orDate.orQty} unit={orPrd.unit} />
            </div>)
          }
      </div>)}
      <h4>{`${months[d1.getMonth()]} ${d1.getDate()}, ${d1.getFullYear()}`}</h4>
      { orPrd.orDates.filter(orDate => orDate.dt.getDate()===d1.getDate())
        .map(orDate => <div key={orDate.dt}>
          {(//console.log('orDate:', orDate),
            orDate.orLines.filter(l => l.orDly||l.orWkly||l.orOne)
            .map((orLine,i) => <div key={i}>
              <ScheduleLine orLine={orLine} />
              <ScheduleLine tot={orDate.orQty} unit={orPrd.unit} />
            </div>)
          )}
      </div>)}
      <h4>{`${months[d2.getMonth()]} ${d2.getDate()}, ${d2.getFullYear()}`}</h4>
      { orPrd.orDates.filter(orDate => orDate.dt.getDate()===d2.getDate())
        .map(orDate => <div key={orDate.dt}>
          {(//console.log('orDate:', orDate),
            orDate.orLines.filter(l => l.orDly||l.orWkly||l.orOne)
            .map((orLine,i) => <div key={i}>
              <ScheduleLine orLine={orLine} />
              <ScheduleLine tot={orDate.orQty} unit={orPrd.unit} />
            </div>)
          )}
      </div>)}
    </div>): null)} */}
    {/* //------------------------------------------------------------------------------// */}
  </>;
};

export default DeliverySchedule;
