import './milk-sale.styles.scss';

const MilkSale = props => {
  const { id, colDt, qty, value, amt, due, uid } = props;
  //const sale = { id, colDt, qty, value, amt, due, uid };
  const dt = colDt? new Date(colDt): 'Date'; //console.log('dt:', dt, (typeof dt));
  let sDt; if((typeof dt)==='object') { sDt =
    `${dt.getDate()}/${dt.getMonth()+1}/${dt.getFullYear().toString().substring(2)}`;
  } else sDt = dt; //console.log('qty:', qty,(typeof qty));
  let sQty = 'Milk Collected', sValue = 'Milk Value', sAmt = 'Sale proceeds', sDue = 'Due (Taka)';
  if((typeof qty)==='number') sQty = `${qty} liter${qty===1?'':'s'}`;
  if((typeof value)==='number') sValue = `Tk ${value}/-`;
  if((typeof amt)==='number') sAmt = `Tk ${amt}/-`;
  if((typeof due)==='number') sDue = `Tk ${due}/-`;
  return (<>
    <div className='milk-sale'>
      <div className='wide-line'>
        <div className='collec-date'>{sDt}</div>
        <div className='qty'>{sQty}</div>
        <div className='value'><h4>{sValue}</h4></div>
        <div className='amt'>{sAmt}</div>
        <div className='due'>{sDue}</div>
      </div>
    </div><hr />
  </>);
};

export default MilkSale;
