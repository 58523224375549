import { createContext, useState } from 'react';
import { fetchFromAPI } from '../helpers';

export const OrdersContext = createContext();

const OrdersContextProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);
  const getOrders = () => {
    const getOrdrs = async () => {
      await fetchFromAPI('get-orders', {method: 'GET'}).then(res => { //console.log("getOrdrs:: res:", res);
        if(res && res.orders) {
          res.orders.sort((o1, o2) => {
            const d1 = new Date(o1.orDt), d2 = new Date(o2.orDt);
            //console.log('d1:', d1, ', d2:', d2, ', d1-d2', (d1-d2));
            return d2 - d1;
          }); //console.log('res.orders:', res.orders);
          setOrders(res.orders); return true;
        } else return false;
      });
    }; getOrdrs();
    // if(!getOrdrs()) setTimeout(() => {
    //   if(!getOrdrs()) setTimeout(() => {
    //     if(!getOrdrs()) setTimeout(() => { getOrdrs(); }, 1900);
    //   }, 1900);
    // }, 1900);
  };
  const updateOrder = async (order, orSt) => { const body = {...order, orSt};
    try {
      const res = await fetchFromAPI('update-order', {body}); console.log('res:', res);
    } catch(error) { console.log('error:', error, ', body:', body); }
  };
  const refundOrdr = async (order) => { const body = {...order};
    try {
      const res = await fetchFromAPI('refund-order', {body}); console.log('res:', res);
    } catch(error) { console.log('error:', error, ', body:', body); }
  };
  const assignOrder = (order) => { updateOrder(order, 'A'); setTimeout(() => {
    getOrders(); console.log('assignOrder:: Got orders 0.4sec after updateOrder');
    setTimeout(() => { getOrders(); }, 2400);
  }, 400); };
  const deliverOrder = (order) => { updateOrder(order, 'D'); setTimeout(() => {
    getOrders(); console.log('deliverOrder:: Got orders 0.4sec after updateOrder');
    setTimeout(() => { getOrders(); }, 2400);
  }, 400); };
  const refundOrder = (order) => { refundOrdr(order); setTimeout(() => {
    getOrders(); console.log('refundOrder:: Got orders 3.5sec after refundOrdr');
    setTimeout(() => { getOrders(); }, 5500);
  }, 3500); };
  const reOrder = (order) => { const ordr = {...order}; ordr.orDt = new Date();
    ordr.pmtMethod = 'COD'; ordr.pmtStatus = ''; ordr.paymentID = '';
    ordr.bkshTrxID = ''; ordr.refundTrxID = ''; ordr.paidAmt = 0;
    ordr.refundAmt = 0; updateOrder(ordr, 'R'); setTimeout(() => {
      getOrders(); console.log('reOrder:: Got orders 0.4sec after updateOrder');
      setTimeout(() => { getOrders(); }, 2400);
  }, 400); };
  const increase = (order, i) => { const ordr = {...order};
    ordr.items[i].quantity += 1; //console.log('ordr:', ordr);
    updateOrder(ordr, ordr.orSt); setTimeout(() => {
      getOrders(); console.log('increase:: Got orders 0.4sec after updateOrder');
      setTimeout(() => { getOrders(); }, 2400);
  }, 400); };
  const decrease = (order, i) => { const ordr = {...order};
    ordr.items[i].quantity -= 1; //console.log('ordr:', ordr);
    updateOrder(ordr, ordr.orSt); setTimeout(() => {
      getOrders(); console.log('decrease:: Got orders 0.4sec after updateOrder');
      setTimeout(() => { getOrders(); }, 2400);
  }, 400); };
  const changeSubtyp = (order, i, subTyp) => { const ordr = {...order};
    ordr.items[i].subTyp = subTyp; console.log('ordr:', ordr);
    updateOrder(ordr, ordr.orSt); setTimeout(() => {
      getOrders(); console.log('changeSubtyp:: Got orders 0.4sec after updateOrder');
      setTimeout(() => { getOrders(); }, 2400);
  }, 400); };
  const changeDelday = (order, i, delDay) => { const ordr = {...order};
    if(ordr.items&& ordr.items[i]) ordr.items[i].delDay = delDay; console.log('ordr:', ordr);
    updateOrder(ordr, ordr.orSt); setTimeout(() => {
      getOrders(); console.log('changeDelday:: Got orders 0.5sec after updateOrder');
      setTimeout(() => { getOrders(); }, 2500);
  }, 500); };
  const prepareOrder = (order) => { updateOrder(order, 'P'); setTimeout(() => {
    getOrders(); console.log('prepareOrder:: Got orders 0.4sec after updateOrder');
    setTimeout(() => { getOrders(); }, 2400);
  }, 400); };
  const cancelOrder = (order) => { updateOrder(order, 'C'); setTimeout(() => {
    getOrders(); console.log('cancelOrder:: Got orders 0.4sec after updateOrder');
    setTimeout(() => { getOrders(); }, 2400);
  }, 400); };
  const reviveOrder = (order) => { updateOrder(order, ''); setTimeout(() => {
    getOrders(); console.log('reviveOrder:: Got orders 0.4sec after updateOrder');
    setTimeout(() => { getOrders(); }, 2400);
  }, 400); };
  const deleteOrder = (order) => {
    const deleteOrdr = async () => { const body = {...order};
      const res = await fetchFromAPI('delete-order', {body}); console.log('res:', res);
    }; deleteOrdr(); setTimeout(() => {
      getOrders(); console.log('deleteOrder:: Got orders 0.4sec after deleteOrdr');
      setTimeout(() => { getOrders(); }, 2400);
  }, 400); };
  const refreshOrders = () => getOrders();
  const clearOrders = () => setOrders([]);
  const contextValues = { orders, refreshOrders, prepareOrder, assignOrder, deliverOrder,
    refundOrder, reOrder, cancelOrder, reviveOrder, deleteOrder, increase, decrease,
    changeSubtyp, changeDelday, clearOrders };
  return (
    <OrdersContext.Provider value={ contextValues }>
      {children}
    </OrdersContext.Provider>
  );
};

export default OrdersContextProvider;
