import { useNavigate } from 'react-router-dom';
import './hero.styles.scss';

const Hero = () => { //console.log("Hero");
  const navigate = useNavigate();
  return (<>
    <section className="hero is-large is-info"> {/* <!-- hero-image --> */}
      <div className=""> {/* hero-body */}
        <img src='https://storage.googleapis.com/organic-orkobd/images/AustralianCows.jpeg' alt='' />
        {/* <p className="hero-title">
          Farming for a healthy Bangladesh
        </p> */}
        {/* <div className='shop-now-btn'>
          <button className='button is-black' id='shop-now'
            onClick={() => navigate('/shop')}>SHOP NOW
          </button>
        </div> */}
      </div>
    </section>

    <div className='hero-description'>
      <h2>Naturally grown, aiming for better health</h2>
      <p>
      ORKO Farms, established in 2023, is an innovative agricultural initiative dedicated to producing
      high-quality, antibiotic-free milk & dairy products in Bangladesh. Milk produced in ORKO farm
      (single source) will meet your daily protein needs, provides essential amino acid,
      strenthen immunity and comes from healthy cows that receive balanced nutrition.<br /><br />

      Our mission is to enhance public health and nutrition by offering superior dairy options that
      contribute to a healthier diet. By prioritizing sustainable farming practices and rigorous
      quality control, ORKO Farms aims to set a new standard in the dairy industry, ensuring ORKO
      products are not only nutritious but also safe, unadulterated and environmentally friendly.
      </p>
    </div>

    <div className='shop-now-btn'>
      <button className='button is-black' id='shop-now'
        onClick={() => navigate('/shop')}>SHOP NOW
      </button>
    </div>
  </>);
};

export default Hero;
