import { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Formik, Field, Form } from 'formik';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber
  , signInWithEmailAndPassword } from 'firebase/auth';
import { UserContext } from '../../context/user-context';
import { CartContext } from '../../context/cart-context';
import { fetchFromAPI } from '../../helpers';

const LocatnPhoneModal = ({locatn, setLocatn, modalShown, setModalShown,
  shopping, setShopping, bAdd, setbAdd, bInc, setbInc, prd}) => {
  //console.log('modalShown:', modalShown, ', shopping:', shopping);
  const { itemCount, qtyCount, addProduct, increase } = useContext(CartContext);
  const { user, setUser } = useContext(UserContext); //console.log("Context user:", user);
  const addIncPrd = () => { if(prd) {
    //console.log('addIncPrd:: bAdd:', bAdd, ', bInc:', bInc, ', prd:',prd);
    if(bAdd) { addProduct(prd); setbAdd(false); }
    else if(bInc) { increase(prd); setbInc(false); }
  } };
  if(shopping&& (itemCount>0)&& user) {
    addIncPrd();
  }
  const [sendingOtp, setSendingOtp] = useState(false);
  const [sendFailed, setSendFailed] = useState(false);
  const [cnfmObj, setCnfmObj] = useState(null), auth = getAuth();
  const [showPwd, setShowPwd] = useState(false), [showNps, setShowNps] = useState(false);
  const [psShown, setPsShown] = useState(false), [npsShwn, setNpsShwn] = useState(false);
  const [npsUpdt, setNpsUpdt] = useState(false);
  console.log('showNps:', showNps, ', npsShwn:', npsShwn, ', npsUpdt:', npsUpdt, ', user:', user);
  const [cntRtry, setCntRtry] = useState(0);
  const phone = localStorage.getItem('phone'), initialValues = { locatn:'Banani'
    , phone:(user && user.phoneNumber? user.phoneNumber.substring(3): (phone?phone:''))
    , name:(user?user.displayName:''), email:(user?user.email:'')
    , type:(user&& user.type?user.type:''), password:'', otp:'', newPass:'', cnfmNps:'' };

  useEffect(() => {
    const setUpRecaptcha = () => { //console.log('useEffect');
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth, 'recaptcha-container', { size: 'invisible', callback: (resp) => {
          console.log('callback:: reCAPTCHA solved, allow signInWithPhoneNumber');
        } }
      ); window.recaptchaVerifier.verify();
    }; setUpRecaptcha();
  }, []); //auth

  useEffect(() => { setTimeout(() => { if(cntRtry > 2) {
    setCnfmObj(null); setCntRtry(0); if(setShopping) setShopping(false);
  } }, 90000); }, [cntRtry]); //console.log('cntRtry:', cntRtry);

  const handleSubmit = (values, actions) => { let shown = false;
    setTimeout(async () => { if(values.locatn && values.locatn!==' ') {
      localStorage.setItem('locatn', values.locatn); shown = true; } else {
        localStorage.removeItem('locatn');
      } setLocatn(values.locatn); console.log('values:', values, ', user:', user);
      if(values.phone) { console.log('showPwd:', showPwd, ', cnfmObj:', cnfmObj);
        const updateUser = async (body) => {
          const res = await fetchFromAPI('update-user', {body}); console.log('res:', res);
        }; if(user) { console.log('showNps:', showNps, ', npsShwn:', npsShwn);
          if(showNps) {
            if(user.displayName && !values.name) values.name = user.displayName;
            if(values.newPass.length > 5) { //Update User with New Password
              if(values.cnfmNps===values.newPass) {
                const body = { uid: user.uid, displayName: values.name, phoneNumber: '+88'+values.phone,
                  email: values.email, type: values.type, password: values.newPass };
                try { updateUser(body); console.log('updateUser body:', body);
                  console.log('Sign-in with email:', values.email, ', newPass:', values.newPass);
                  const res = await signInWithEmailAndPassword(auth, values.email, values.newPass);
                  setNpsUpdt(true); console.log('Successfully signed-in, res:', res);
                } catch(error) { console.log('Error updating user:', error); }
              } else shown = false;
            }
          } else { console.log('User is already signed in, calling addIncPrd()');
            addIncPrd();
          }
        } else { //!user
          let sendOtp = false, existUsr = showPwd;
          if(!values.email) values.email = 'p_' + values.phone + '@test.com';
          if(cnfmObj) { //Show OTP
            if(!npsShwn) {
              if(values.otp) { let usr = null; //Verify OTP and Sign-Up
                try {
                  const res = await cnfmObj.confirm(values.otp); console.log('Success res:', res);
                  addIncPrd(); console.log('Signed in through OTP, addIncPrd() has been called');
                  const { phoneNumber } = res.user; usr = res.user; //console.log('phoneNumber:', phoneNumber);
                } catch(e) {
                  setCntRtry(cntRtry+1); shown = false; console.log('Otp error:', e);
                } finally { //Update User
                  if(usr) {
                    const body = { uid: usr.uid, displayName: values.name, phoneNumber: usr.phoneNumber,
                      email: values.email, type: values.type, password: values.password };
                    try { updateUser(body); setShowNps(true); shown=false;
                      setUser({ uid: usr.uid, displayName: values.name,
                      phoneNumber: usr.phoneNumber, email: values.email, type: values.type });
                    } catch(error) { console.log('Error updating user:', error); }
                } }
              } else { setCntRtry(cntRtry+1); shown = false; }
            }
          } else if(!showPwd) { //Check if user exists
            //----------------------------------------//
            const url = 'get-user?phone='+values.phone; console.log('url:', url);
            const res = await fetchFromAPI(url, {method: 'GET'}); console.log('res:', res);
            if(res && res.uid) { existUsr = true; console.log('existUsr:', existUsr);
              if(res.displayName && !values.name) values.name = res.displayName;
              if(res.email) values.email = res.email;
              if(res.type) values.type = res.type;
            }
            //----------------------------------------//
          }
          if(existUsr) { //User Exists
            if(values.password) { //Sign-In
              //------------------------------------------------------------------------------//
              try {
                await signInWithEmailAndPassword(auth, values.email, values.password);
                if(auth.currentUser) { const { uid, displayName, email } = auth.currentUser;
                  addIncPrd(); console.log('User signed in, addIncPrd() has been called');
                  setUser({ uid, displayName, email, phone: values.phone, type: values.type });
              } } catch(error) { sendOtp = true; console.log(error); }
              //------------------------------------------------------------------------------//
            } else { //console.log('psShown:', psShown); //Show Password if not shown
              if(psShown) sendOtp = true; //Forgot Password
              else { shown = false; console.log('shown:', shown);
                if(!showPwd) setShowPwd(true); else setPsShown(true);
            } }
          } else sendOtp = true;
          if(sendOtp) { //Send OTP-User doesn't exist/forgot password
            //console.log('sendOtp:', sendOtp, ', cntRtry:', cntRtry, ', cnfmObj:', cnfmObj);
            //-----------------------------------------------------------//
            if(!cnfmObj) { setSendingOtp(true); console.log('Sending OTP, phone:', values.phone);
              try { const appVerifier = await window.recaptchaVerifier;
                try {
                  const obj = await signInWithPhoneNumber(auth, '+88' + values.phone, appVerifier);
                  setCnfmObj(obj); setSendingOtp(false); shown = false; console.log('obj:', obj);
                } catch(error) { setSendFailed(true); console.log('error:', error); }
              } catch(error) { setSendFailed(true); console.log('error:', error); }
            } else { setCntRtry(cntRtry+1); shown = false; }
            //-----------------------------------------------------------//
        } }
      }
      if(shown) setModalShown(true); actions.setSubmitting(false);
    }, 400);
  };
  const validate = values => {
    const errors = {}; if(locatn&& locatn!==' ') {
      if(!values.phone) errors.phone  = 'Phone number is Required';
      else if(!/^01[0-9]{8,}$/i.test(values.phone)) errors.phone = 'Invalid phone number';
    }
    if(values.newPass && values.newPass.length<6)
      errors.newPass = 'Password must be at least 6 characters long';
    if(values.newPass&& values.newPass.length>=6&& values.cnfmNps!==values.newPass)
      errors.cnfmNps = 'Passwords must match';
    return errors;
  }; Modal.setAppElement('#root');
  //console.log('modalShown:', modalShown, ', shopping:', shopping, ', shCkout:', shCkout, ', itemCount:', itemCount);
  return (<>
    <div id='recaptcha-container'></div>
    {((shopping&& (itemCount>0)&& user)||(!modalShown||(!user&&cntRtry<3)))&& <Modal
      isOpen={true} style={{content: {top:'15vh', right:'20vw', bottom:'auto', left:'20vw'}}}>
      {shopping&& (itemCount>0)&& user&& (npsUpdt||!showNps)&& <div //style={{width:'15vw', margin:'0 auto'}}
        className='proceed'>
        <div style={{textAlign:'center', marginBottom:'2vh'}}>NUMBER OF ITEMS: {itemCount}</div>
        <div style={{textAlign:'center', marginBottom:'2vh'}}>TOTAL QUANTITY: {qtyCount}</div>
        <a href='/cart' className='button is-black nomad-btn' style={{textDecoration:'none'
        , padding:0, lineHeight:2.5}}>PROCEED TO CHECKOUT</a>{setShopping&&
        <button className='button nomad-btn' onClick={() => {
          setShopping(false); setModalShown(true); }}>NOT NOW</button>}
      </div>}
      {(!modalShown||(!user&&cntRtry<3))&& !(shopping&& (itemCount>0)&& user&& (npsUpdt||!showNps))
      && <Formik
        initialValues={initialValues} onSubmit={handleSubmit}
        validate={validate}>{({values, errors, isSubmitting, setValues}) => { const { phone,
        password, newPass, cnfmNps } = errors; //console.log('values:',values,', errors:',errors);
        return <Form className='data-form'>
          {(!locatn||locatn===' ')&& <div><label htmlFor='locatn' className='location'
            //style={{textAlign:'left', paddingBottom:'7px', marginLeft:'-45px'}}
            >Location</label>
            <Field as='select' id='locatn' name='locatn' className={'nomad-input'}>
            <option value=' '> </option>
            <option value='Banani'>Banani</option>
            <option value='Banani DOHS'>Banani DOHS</option>
            <option value='Mohakhali DOHS'>Mohakhali DOHS</option>
            <option value='Gulshan'>Gulshan</option>
            <option value='Baridhara'>Baridhara</option>
            <option value='Baridhara DoHS'>Baridhara DoHS</option>
          </Field></div>}
          {(locatn&& locatn!==' ')&& <div className='wide-line'><label htmlFor='phone'
            className='phone' //style={{marginBottom:'1.4rem', flex:'1'}}
            >Mobile Number</label>
            <Field type='text' name='phone' placeholder='Mobile Number' onBlur={async e => {
              const ph = localStorage.getItem('phone');
              console.log('onBlur:: e.target.value:', e.target.value, ',ph:', ph);
              if(ph !== values.phone) { //phone input changed
                localStorage.setItem('phone', values.phone);
                if(sendFailed) { setSendFailed(false); setSendingOtp(false); }
              }
              if(values.phone.length>9) {
                const url = 'get-user?phone='+e.target.value; //console.log('url:', url);
                const res = await fetchFromAPI(url, {method: 'GET'}); //console.log('res:', res);
                if(res){
                  if(res.displayName || res.email) { setValues(values => {
                    if(res.displayName) values.name = res.displayName;
                    if(res.email) values.email = res.email;
                    if(res.type) values.type = res.type; //console.log('values:', values);
                    return values;
                  }); } //console.log('res.uid:', res.uid, !user);
                  if(res.uid) { if(!user) setShowPwd(true); }
              } }
            } } className={'nomad-input ' + (phone? 'error': '')} style={{flex:'1'}}
            disabled={cnfmObj} id='phone' />
          </div>}
          {(locatn&& locatn!==' ')&& <div className='wide-line'><label //style={{flex:'1'}}
            className='name'></label>
            <div //style={{marginTop:'-1rem', marginBottom:'0.8rem', textAlign:'left', flex:'1'}}
            className='name'>{values.name}</div>
          </div>}
          {(locatn&& locatn!==' ')&& !showNps&& <div className='wide-line'><label htmlFor='password'
            className='password' //style={{marginBottom:'1.4rem', flex:'1'}}
            >{showPwd?'Password':<br />}</label>
            {showPwd&& <Field type='text' name='password' placeholder='Password' onBlur={e => {
              setPsShown(true);
            }} className={'nomad-input ' + (password? 'error': '')} style={{flex:'1'}} />}
          </div>}

          {(locatn&& locatn!==' ')&& sendingOtp&& <div className='otp'>
            We are sending an OTP to your mobile
          </div>}
          {(locatn&& locatn!==' ')&& sendFailed&& <div className='otp'>
            Failed to send the OTP, please check your mobile number (and/or call Orko Support)
          </div>}

          {(cnfmObj&& !showNps)&& <div className='wide-line'><label htmlFor='otp'
            className='otp' //style={{marginBottom:'1.4rem', flex:'1'}}
            >Enter OTP (Sent to your Mobile)</label>
            <Field type='text' name='otp' placeholder='Verification Code (OTP)'
              //onBlur={e => { setOtpShwn(true); }}
              className={'nomad-input otp '} style={{flex:'1'}} />
          </div>}
          {(showNps)&& <div className='wide-line'><label htmlFor='newPass'
            className='newpass' //style={{marginBottom:'1.4rem', flex:'1'}}
            >Set a Password for Future Use</label>
            <Field type='password' name='newPass' placeholder='Must be at least 6 characters long'
              onBlur={e => { setNpsShwn(true); }}
              className={'nomad-input newpass ' + (newPass? 'error': '')} style={{flex:'1'}} />
          </div>}
          {showNps&& values.newPass.length>5&& <div className='wide-line'><label htmlFor='cnfmNps'
            className='newpass' //style={{marginBottom:'1.4rem', flex:'1'}}
            >Confirm Password</label>
            <Field type='password' name='cnfmNps' placeholder='Passwords must match'
              //onBlur={e => { setNpsShwn(true); }}
              className={'nomad-input newpass ' + (cnfmNps? 'error': '')} style={{flex:'1'}} />
          </div>}
          {/* {(phone|| password) && <div className='error-message'>
            { <p>{(phone? phone: password)}</p> }
          </div>} */}
          <div className='submit-btn'><button type='submit' disabled={isSubmitting}
            className='button is-black nomad-btn submit'>Submit</button></div>
        </Form>
      }}</Formik>}
    </Modal>}
  </>);
};

export default LocatnPhoneModal;
