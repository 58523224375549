import { useState } from 'react';
import { PlusCircleIcon, MinusCircleIcon, TrashIcon } from '../../icons';

const CartItem = props => {
  let { id, title, imageUrl, price, quantity, unit, subTyp, delDay, description
    , minQty, increase, decrease, setSubTyp, setDelDay, removeProduct } = props;
  if(!subTyp) subTyp = 'One off'; if(!delDay) { const dt = new Date();
    let mn = (dt.getMonth()+1).toString(); if(mn.length<2) mn = '0' + mn;
    let dy = (dt.getDate()+1).toString(); if(dy.length<2) dy = '0' + dy;
    delDay = `${dt.getFullYear()}-${mn}-${dy}`; //console.log('delDay:', delDay);
  } //console.log('subTyp:', subTyp, ', delDay:', delDay);
  const product = { id, title, imageUrl, price, quantity, unit, subTyp, delDay, description
    , minQty }; //console.log('product:', product, ', minQty:', minQty);
  const [subsType, setSubsType] = useState(subTyp); //? subTyp: 'One off'
  // const today = new Date(); //console.log('today:', today, today.getDay());
  // let day = ''; switch(today.getDay()) { // 0: Sun
  //   case 6: case 0: day = 'Mon'; break; // Sat, Sun
  //   case 1: case 2: case 3: day = 'Thu'; break; // Mon,Tue,Wed
  //   case 4: case 5: day = 'Sat'; break; // Thu, Fri
  // }
  // const [delivDay, setDelivDay] = useState(delDay); //? delDay: day
  const [weekDay, setWeekDay] = useState(delDay);
  const [mnthDay, setMnthDay] = useState(delDay);
  return (
    <div className='cart-item'>
      <div className='wide-line'>
        <div className='item-image'><img src={imageUrl} alt='product' /></div>
        <div className='name-price'><h4>{title}</h4><p>Tk {price}/-</p></div>
        <div className='quantity'>
          <p>{`Quantity: ${quantity}`} <br />{`${unit}`}</p>
        </div>
        <div className='btns-container'>
          <button className='btn-increase' onClick={() =>
            increase(product)}><PlusCircleIcon width='20px' /></button>
          {
            quantity===minQty && <button className='btn-trash' //1
              onClick={() => removeProduct(product)}><TrashIcon width='20px' /></button>
          }
          { quantity > minQty && <button className='btn-decrease' //1
              onClick={() => decrease(product)}><MinusCircleIcon width='20px' /></button>
          }
        </div>
      </div>
      <form className='cart-form'>
        <div className='wide-line'>
          <label htmlFor='subsType'>Subscription Type</label>
          {<select name='subsType' value={subsType} onChange={e => { let dt = new Date();
              if(e.target.value==='Weekly') {
                const days =['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
                if(weekDay.length<3) { let dy = dt.getDay()+1; if(dy>6) dy = 0;
                  setWeekDay(days[dy]); product.delDay = days[dy]; setDelDay(product);
                } else if(weekDay.length>3) { dt = new Date(mnthDay); //weekDay
                  setWeekDay(days[dt.getDay()]); product.delDay = days[dt.getDay()]; setDelDay(product);
                  //console.log('dt:', dt, dt.getDay(), days[dt.getDay()]);
                }
              } else if(e.target.value!=='Weekly' && mnthDay.length<4) {
                let mn = (dt.getMonth()+1).toString(); if(mn.length<2) mn = '0' + mn;
                let dy = (dt.getDate()+1).toString(); if(dy.length<2) dy = '0' + dy;
                setMnthDay(`${dt.getFullYear()}-${mn}-${dy}`);
                product.delDay = `${dt.getFullYear()}-${mn}-${dy}`; setDelDay(product);
              } product.subTyp = e.target.value; setSubTyp(product);
            setSubsType(e.target.value); }} className={''} id='subsType'>
            <option value='Daily'>Daily</option>
            <option value='Weekly'>Weekly</option>
            <option value='One off'>One off</option>
          </select>}
        </div>
        <div className='wide-line'>
          <label htmlFor='delivDay'>{subsType==='Daily'?'Starting From':'Delivery Day'}</label>
          {subsType!=='Weekly'&& <input type='date' name='delivDay' onChange={e => {
            product.delDay = e.target.value; setDelDay(product);
            setMnthDay(e.target.value); }} value={mnthDay} id='delivDay'/>}
          {subsType==='Weekly'&& <select name='delivDay' value={weekDay} onChange={e => {
            product.delDay = e.target.value; setDelDay(product);
            setWeekDay(e.target.value); }} className={''} id='delivDay'>
            <option value='Sat'>Saturday</option>
            <option value='Sun'>Sunday</option>
            <option value='Mon'>Monday</option>
            <option value='Tue'>Tuesday</option>
            <option value='Wed'>Wednesday</option>
            <option value='Thu'>Thursday</option>
            <option value='Fri'>Friday</option>
          </select>}
        </div>
      </form>
    </div>
  );
};

export default CartItem;
