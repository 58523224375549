import { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { Font, pdf, PDFViewer } from '@react-pdf/renderer'; //
import { Buffer } from "buffer";
import { UserContext } from '../../context/user-context';
import { OrdersContext } from '../../context/orders-context';
import { sendMail } from '../../helpers.js';
import RobotoRegular from "../../fonts/Roboto/Roboto-Regular.ttf";
import RobotoBold from "../../fonts/Roboto/Roboto-Bold.ttf";
import InvoiceDoc, { toHtml } from './invoice-doc'; //
import styles from './invoice-doc.styles.js';

Font.register({
  family: "Roboto", fonts: [
    { src: RobotoRegular, fontWeight: 400 },
    { src: RobotoBold,    fontWeight: 700 },
  ]
});

const Invoice = () => {
  const params = useParams(), { id } = params; console.log('id:', id);
  const { user, setUser } = useContext(UserContext); //console.log("Context user:", user);
  const { orders, refreshOrders } = useContext(OrdersContext); //console.log('orders:', orders);
  const navigate = useNavigate();
  const auth = getAuth(); //console.log("Invoice:: auth:", auth);
  const [order, setOrder] = useState(null);
  const [click, setClick] = useState(false);

  const checkOrder = () => {
    let type = '', usrFmStor = sessionStorage.getItem('user'); //console.log('usrFmStor:', usrFmStor);
    let usr = null; if(usrFmStor) usr = JSON.parse(usrFmStor);
    if(sessionStorage.getItem('signedOut')==='true') { console.log('Signed Out');
      usr = null; setUser(null); sessionStorage.removeItem('signedOut');
    }
    const getPhoneType = async (uid, displayName, email, phone) => { if(auth.currentUser) {
      const idTokenResult = await auth.currentUser.getIdTokenResult(true);
      if(idTokenResult&& idTokenResult.claims) { //console.log('idTokenResult.claims.type:', idTokenResult.claims.type);
        if(!phone) phone = idTokenResult.claims.phone;
        type = idTokenResult.claims.type; //console.log('Current User type:', type, phone); //, uid, displayName, email
        if(email && !phone) { //Temporary code to set phone
          if(email[0]==='p' && email[1]==='_') { phone = email.substring(2);
            phone = phone.split('@')[0]; console.log('phone:', phone);
        } } usr = { uid, displayName, email, phone, type };
        setUser(usr); sessionStorage.setItem('user', JSON.stringify(usr));
    } } }; //console.log('usr&& usr.uid:', (usr&& usr.uid), usr);
    if(usr&& usr.uid) { setUser(usr); console.log('Session usr:', usr);
      if(!usr.type) getPhoneType(usr.uid, usr.displayName, usr.email, usr.phone);
      if(orders&& orders.length < 1) refreshOrders(); //console.log('orders:', orders);
    } else
    if(auth.currentUser) { const { uid, displayName, email, phoneNumber } = auth.currentUser;
      let phone = ''; if(phoneNumber) phone = phoneNumber;
      usr = { uid, displayName, email, phone, type }; //console.log('phoneNumber:', phoneNumber);
      setUser(usr); sessionStorage.setItem('user', JSON.stringify(usr));
      getPhoneType(uid, displayName, email, phone); //console.log('phone:', phone, ', type:', type);
      if(orders&& orders.length < 1) refreshOrders(); //console.log('orders:', orders);
    } else setUser(null); return auth.currentUser;
  }

  useEffect(() => { console.log('useEffect:: auth.currentUser:', auth.currentUser);
    if(!checkOrder()) setTimeout(() => {
      if(!checkOrder()) setTimeout(() => { checkOrder(); }, 900);
    }, 900);
  }, [auth.currentUser,orders.length,setUser]);

  useEffect(() => { console.log('useEffect:: orders:', orders);
    if(orders&& orders.length > 0) {
      const order = orders.find(item => item.id===id);
      if(order) setOrder(order); console.log('useEffect:: order:', order);
    }
  }, [orders]);

  const mailHandler = (e) => { console.log('mailHandler:: order:', order);
    setClick(true); //console.log('Testing'); return;
    e.stopPropagation(); if(!order) return false;
    const dt = new Date(order.orDt); console.log('dt:', dt);
    const fileName = 'Invoice_' + `${dt.getFullYear()}${dt.getMonth()}${dt.getDate()}`
    + '_' + order.id.substr(order.id.length-4) + '.pdf'; console.log('fileName:', fileName);
    pdf(<InvoiceDoc order={order} />).toBlob().then(async (blob) => { //console.log("blob:", blob);
      //handleConfirmSendMail(blob, fileName);
      //-------------------------------------//
      const arBuffer = await blob.arrayBuffer(); console.log("mailHandler: arBuffer:", arBuffer);
      const buffer = Buffer.from(arBuffer); console.log("mailHandler: buffer:", buffer);
      const letr = toHtml(order); //console.log("letr:", letr); //background-color:#f4f4f4;
      let html = `<html>
        <head><link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
          <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400;1,700&display=swap"
          rel="stylesheet"><style>
          * {margin:0;padding:0;box-sizing:border-box;}
          body {font-family:Roboto,"Helvetica Neue",Arial,sans-serif;font-size:1rem;line-height:1.5;}
          .big {font-size:36px;color:grey;margin-left:-3.1px;}
          .bold {font-weight:bold;}
          h1 {font-size:20px;font-weight:bold;text-align:center;}
          .img-container {width:70px;height:70px;float:right;}
          img {width:70px;height:70px;text-align:right;}
          .canvas {position:absolute;top:40px;right:45px;
            background-color:rgba(255,255,255,0.2);height:70px;width:70px;}
          .center {text-align:center;}
          .col1 {width:500px;} .col2 {width:300px;} .grey {color:grey;}
          .item {width:260px;} .qty {width:200px;} .rate {width:130px;}
          .line {display:flex;flex-direction:row;}
          .page {padding:48px 48px 0 36px;position:relative;}
          .pb8 {padding-bottom:8px;} .pr12 {padding-right:12px;}
          .size10 {font-size:10px;} .size12 {font-size:12px;}
          .size14 {font-size:14px;padding-bottom:8px;}
          .right {text-align:right;}
          p span {display:inline-block;vertical-align:middle;}
        </style></head>
        <body><div style="box-sizing:border-box;">
          `; html+=letr; html+=`
        </div></body>
      </html>`; //console.log(html); /*position:absolute;bottom:40px;left:56px;*/
      const content = { //mahbubur.rahman.64@gmail.com, mahbub.bysontech@gmail.com
        email: order.shipping?.email, subject: fileName.substring(0,fileName.length-4)
        +' - '+order.shipping?.name, html, fileName, buffer, cc: 'arif@orkobd.com'
      }; let userEmail = user?.email; console.log('user:', user);
      if(userEmail[0]==='p'&&userEmail[1]==='_') userEmail = ''; console.log('userEmail:', userEmail);
      content.bcc = 'mahbubur.rahman.64@gmail.com'+(userEmail?', '+userEmail:'');
      //-------------------------------------//
      sendMail(content).then((json) => { console.log("Mail sent.");
        console.log("sendMail:: json:", json);
        navigate('/mail-sent?sentStat=true');
        // setInputMode(0); setTimeout(() => { autoRef.current?.focus();
        //   setAlertMesg("Email sent to address: "+formValues.email); setBtn1Text("OK");
        //   btn1Handler = handleAlertClick; setBtn2Text(""); setAlertOpen(true);
        // }, 100);
      }).catch(error => { console.log("Mail could NOT be sent.");
        console.log("sendMail:: error:", error);
        navigate('/mail-sent?sentStat=false');
      });
      //-------------------------------------//
    });
  };

  return <div>{order&& order.shipping?.email?
    <button className='button' style={styles.btn} disabled={click}
      onClick={mailHandler}>SEND BY EMAIL</button>: null}
    <PDFViewer style={styles.window}>
      {order? <InvoiceDoc order={order} />: null}
    </PDFViewer>
  </div>;
};

export default Invoice;
