import { useState } from 'react';
import { PlusCircleIcon, MinusCircleIcon, TrashIcon } from '../../icons';

const OrderItem = props => {
  const { i, id, title, price, quantity, unit, subTyp, delDay, change, increase, decrease
    , changeSubtyp, changeDelday, order } = props;
  const [subsTyp, setSubsTyp] = useState(subTyp), [deliDay, setDeliDay] = useState(delDay);
  const incrHandler = () => { console.log('incrHandler:: order:', order, ', id:', id, ', i:', i);
    increase(order, i);
  }; let day = delDay;
  if(day.length>3) { const dt = new Date(day);
    day = `${dt.getDate()}/${dt.getMonth()+1}/${dt.getFullYear()}`;
  } //console.log('title:', title, ', subTyp:', subTyp, ', delDay:', delDay);
  return (<>
    <div className='order-item line' style={{display:'flex'}}>
      <div className='title'>{title}</div>
      <div className='price hide-in-mobile'>Tk {price}/-</div>
      <div className='quantity'>{quantity} {unit}</div>
      <div className='price hide-in-desktop'>{price}/-</div>
      <div className='line-total hide-in-desktop'>{price*quantity}/-</div>
      <div className='delivery hide-in-mobile'>
        {change? //<>{subTyp}<br />{day}</>
        <>
          <select onChange={e => { console.log('e.target.value:', e.target.value);
            changeSubtyp(order, i, e.target.value); let dt = new Date();
            const days =['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
            if(e.target.value==='Weekly') {
              if(deliDay.length<3) { let dy = dt.getDay()+1; if(dy>6) dy = 0;
                setDeliDay(days[dy]); changeDelday(order, i, days[dy]);
              } else if(deliDay.length>3) { dt = new Date(deliDay);
                setDeliDay(days[dt.getDay()]); changeDelday(order, i, days[dt.getDay()]);
              }
            } else if(e.target.value!=='Weekly' && deliDay.length<4) { let mn, dy, nx = -1;
              if(deliDay.length===3) nx = days.findIndex(day => day===deliDay);
              if(nx >= 0) { dy = dt.getDay(); if(nx < dy) nx += 6;
                const addDay = nx - dy; dt.setDate(dt.getDate() + addDay);
              }
              mn = (dt.getMonth()+1).toString(); if(mn.length<2) mn = '0' + mn;
              dy = (dt.getDate()+1).toString(); if(dy.length<2) dy = '0' + dy;
              const newDay = `${dt.getFullYear()}-${mn}-${dy}`;
              setDeliDay(newDay); changeDelday(order, i, newDay);
            } setSubsTyp(e.target.value); console.log('order:', order);
          }} defaultValue={subsTyp}>
            <option value='Daily'>Daily</option>
            <option value='Weekly'>Weekly</option>
            <option value='One off'>One off</option>
          </select><br />
          {subsTyp==='Weekly'? <select onChange={e => { console.log('e.target.value:', e.target.value);
            setDeliDay(e.target.value); console.log('order:', order);
            changeDelday(order, i, e.target.value);
          }} defaultValue={deliDay}>
            <option value='Sat'>Saturday</option>
            <option value='Sun'>Sunday</option>
            <option value='Mon'>Monday</option>
            <option value='Tue'>Tuesday</option>
            <option value='Wed'>Wednesday</option>
            <option value='Thu'>Thursday</option>
            <option value='Fri'>Friday</option>
          </select>: //day //Show Calendat
            <input type='date' value={( //console.log('deliDay:', deliDay),
              deliDay
            )} onChange={e => { //console.log('input date e.target.value:', e.target.value );
              setDeliDay(e.target.value); changeDelday(order, i, e.target.value);
            }} />
          }
        </>
        : <>{subTyp}<br />{day}</>}
      </div>
      <div className='btns-container'>
        {change&& <button className='btn-increase' onClick={incrHandler
        }><PlusCircleIcon width='20px' /></button>}
        {change&& <button className='btn-decrease' onClick={() =>
        decrease(order, i)}><MinusCircleIcon width='20px' /></button>}
      </div>
    </div>
  </>);
};

export default OrderItem;
