import { useContext, useEffect, useState } from 'react';
import { ProductsContext } from '../../context/products-context';
import { CartContext } from '../../context/cart-context';
import { isInCart } from '../../helpers';
import LocatnPhoneModal from '../shared/locatn-phonmodal';
import Layout from '../shared/layout';
import withRouter from '../with-router';
import './single-product.styles.scss';

//const SingleProduct = ({ match, history: {push} }) => {
const SingleProduct = ({ params, navigate }) => {
  //const push = history? history.push: null;
  //console.log("SingleProduct:: match:", match, ", history:", history);
  //console.log("SingleProduct:: params:", params, ", navigate:", navigate);
  const [locatn, setLocatn ] = useState(localStorage.getItem('locatn'));
  const [shopping, setShopping ] = useState(false); //console.log('locatn:', locatn);
  const [bAdd, setbAdd] = useState(false), [bInc, setbInc] = useState(false);
  const [modalShown, setModalShown ] = useState(false);
  const [shModal, setShModal] = useState(false); if(shopping&& !shModal) setShModal(true);
  const { products } = useContext(ProductsContext); //console.log("products:", products);
  const { addProduct, cartItems, increase } = useContext(CartContext);
  const { id } = params; //match.params;
  const [product, setProduct] = useState(null);
  useEffect(() => {
    const product = products.find(item => Number(item.id)===Number(id));
    // If product does not exist, redirect to shop page
    // if(!product) return push('/shop');
    //if(!product) return push? push('/shop'): null;
    if(!product) return navigate('/shop');
    setProduct(product);
  }, [id, products, navigate]);
  if(!product) return null;
  const {imageUrl, title, price, unit, description} = product; //, minQty, active
  const itemInCart = isInCart(product, cartItems);
  return (
    <Layout setShopping={setShopping} setShModal={setShModal}>
      {shModal&& <LocatnPhoneModal locatn={locatn} setLocatn={setLocatn} shopping={shopping}
        setShopping={setShopping} modalShown={modalShown} setModalShown={setModalShown}
        bAdd={bAdd} setbAdd={setbAdd} bInc={bInc} setbInc={setbInc} prd={product} />}
      <div className='single-product-container'>
        <div className='product-image'>
          <img src={imageUrl} alt='product' />
        </div>
        <div className='product-details'>
          <div className='name-price'>
            <h3>{title}</h3>
            <p>Tk {price} {unit? '/ '+unit: ''}</p>
          </div>
          <div className='add-to-cart-btns'>
            { !itemInCart && <button disabled={!product.active}
              className='button is-white nomad-btn' id='btn-white-outline' onClick={() => {
              setShopping(true); if(setbAdd) setbAdd(true); else
              if(modalShown) addProduct(product); }}>ADD TO CART</button>
            }
            { itemInCart && <button disabled={!product.active}
              className='button is-white nomad-btn' id='btn-white-outline' onClick={() => {
              setShopping(true); if(setbInc) setbInc(true); else
              if(modalShown) increase(product); }}>ADD MORE</button>
            }
            <button disabled={!(itemInCart && product.active)}
              className='button is-black nomad-btn' id='btn-white-outline'
              onClick={() => navigate('/cart')}>PROCEED TO CHECKOUT</button>
          </div>
          <div className='product-description'>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(SingleProduct);
