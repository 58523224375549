const storeCartItems = (cartItems) => {
  const cart = cartItems.length > 0? cartItems: [];
  localStorage.setItem('cart', JSON.stringify(cart));
};

export const sumItems = cartItems => {
  storeCartItems(cartItems);
  return {
    itemCount: cartItems.reduce((total, prod) => total + (prod.quantity? 1: 0), 0),
    qtyCount: cartItems.reduce((total, prod) => total + prod.quantity, 0),
    total: cartItems.reduce((total, prod) => total + prod.quantity*prod.price, 0)
  };
};

const cartReducer = (state, action) => { console.log('payload:', action.payload);
  //const index = state.cartItems.findIndex(item => item.id===action.payload.id);
  const index = state.cartItems.findIndex(item => (
    action.payload? item.id===action.payload.id: false));
  switch(action.type) {
    case "ADD_ITEM": // console.log('ADD_ITEM: action.payload:', action.payload);
      if(!state.cartItems.find(item => item.id===action.payload.id)) {
        const minQty = (action.payload.minQty? parseFloat(action.payload.minQty): 1);
        state.cartItems.push({ ...action.payload, quantity: (!isNaN(minQty)?minQty:1) });
      } return { ...state, cartItems: [...state.cartItems], ...sumItems(state.cartItems) };
    case "INCREASE":
      const increaseIndex = state.cartItems.findIndex(item => item.id===action.payload.id);
      state.cartItems[increaseIndex].quantity++;
      return { ...state, cartItems: [...state.cartItems], ...sumItems(state.cartItems) };
    case "DECREASE":
      const decreaseIndex = state.cartItems.findIndex(item => item.id===action.payload.id);
      const product = state.cartItems[decreaseIndex], minQty = (product.minQty? parseFloat(product.minQty): 1);
      //console.log('product:', product, ', minQty:', minQty);
      if(product.quantity > (!isNaN(minQty)?minQty:1)) product.quantity--;
      return { ...state, cartItems: [...state.cartItems], ...sumItems(state.cartItems) };
    case "SET_SUBTYP":
      state.cartItems[index].subTyp = action.payload.subTyp;
      return { ...state, cartItems: [...state.cartItems], ...sumItems(state.cartItems) };
    case "SET_DELDAY":
      state.cartItems[index].delDay = action.payload.delDay;
      return { ...state, cartItems: [...state.cartItems], ...sumItems(state.cartItems) };
    case "REMOVE_ITEM":
      const newCartItems = state.cartItems.filter(item => item.id !== action.payload.id);
      return { ...state, cartItems: [...newCartItems], ...sumItems(newCartItems) };
    case "CLEAR":
      localStorage.removeItem('cart');
      return { cartItems: [], itemCount: 0, total: 0 };
    default: return state;
  }
};

export default cartReducer;
