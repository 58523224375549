//import 'dotenv/config';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ProductsContextProvider from './context/products-context';
import OrdersContextProvider from './context/orders-context';
import CartContextProvider from './context/cart-context';
import UserContextProvider from './context/user-context';
import { Elemnts } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// console.log('REACT_APP_BASE_URL_API:', process.env.REACT_APP_BASE_URL_API);
// console.log('REACT_APP_BASE_URL_WEB:', process.env.REACT_APP_BASE_URL_WEB);
// console.log('NODE_ENV:', process.env.NODE_ENV);

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ProductsContextProvider>
      <CartContextProvider>
        {/* <Elemnts stripe={stripePromise}> */}
          <UserContextProvider>
            <OrdersContextProvider>
              <App />
            </OrdersContextProvider>
          </UserContextProvider>
        {/* </Elemnts> */}
      </CartContextProvider>
    </ProductsContextProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
