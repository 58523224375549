import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/user-context';
import Layout from '../../shared/layout';
import LocatnPhoneModal from '../../shared/locatn-phonmodal';
import FeaturedProduct from '../../shared/featured-product';
import { ProductsContext } from '../../../context/products-context';
import './shop.styles.scss';

const Shop = () => { //localStorage.removeItem('locatn'); //console.log("Shop");
  const { user } = useContext(UserContext), navigate = useNavigate();
  useEffect(() => { if(user && user.type==='D') navigate('/orders'); });
  const [locatn, setLocatn ] = useState(localStorage.getItem('locatn'));
  const [shopping, setShopping ] = useState(false); //console.log('locatn:', locatn);
  const [bAdd, setbAdd] = useState(false), [bInc, setbInc] = useState(false);
  const [ prd, setPrd ] = useState(null);
  const [modalShown, setModalShown ] = useState(false);
  const [shModal, setShModal] = useState(false); if(shopping&& !shModal) setShModal(true);
  const { products } = useContext(ProductsContext); //console.log('products:', products);
  const allProducts = products.map(product => (<FeaturedProduct {...product}
    setShopping={setShopping} setbAdd={setbAdd} setbInc={setbInc} setPrd={setPrd}
    modalShown={modalShown} key={product.id} /> //shopping={shopping}
  ));
  //---------------------------------------------------------------//
  // useEffect(() => { console.log('UseEffect:');
  //   fetch('https://rscombd.com/api/inv-template', {
  //     // credentials: "include", statusCode: 200,
  //     method: 'GET', mode: 'cors',
  //     headers: {
  //       "Access-Control-Allow-Origin": "http://localhost:3000", //"*"
  //       'Content-Type': 'application/json',
  //       "Access-Control-Allow-Methods": "OPTIONS,GET", //"OPTIONS,POST,GET,PATCH"
  //       "Access-Control-Allow-Headers": "Content-Type",
  //     }
  //   }).then(response => { console.log('response:', response);
  //     // console.log('response.json():', response.json());
  //     return response.json();
  //   }).then(data => console.log('data:', data))
  //   .catch(e => console.log('error:', e));
  // }, []);
  //---------------------------------------------------------------//
  return (
    <Layout setShopping={setShopping} setShModal={setShModal}>
      {shModal&& <LocatnPhoneModal locatn={locatn} setLocatn={setLocatn} shopping={shopping}
        setShopping={setShopping} modalShown={modalShown} setModalShown={setModalShown}
        bAdd={bAdd} setbAdd={setbAdd} bInc={bInc} setbInc={setbInc} prd={prd} />}
      <div className='product-list-container'>
        <h2 className='product-list-title'>Shop</h2>
        <div className='product-list'>
          {allProducts}
        </div>
      </div>
    </Layout>
  );
};

export default Shop;
