import { useContext } from 'react';
import { Formik, Field, Form } from 'formik';
import { UserContext } from '../../../context/user-context';

const validate = values => {
  const { name, phone, email, address } = values, errors = {};
  if(!email) errors.email = 'Required';
  if(!phone) errors.phone = 'Required';
  if(!name)  errors.name  = 'Required';
  if(!address) errors.address = 'Required';
  return errors;
}

const ShippingAddress = ({ setShipping, addrTaken, setAddrTaken }) => {
  const { user } = useContext(UserContext); //console.log("user:", user);
  const locatn = localStorage.getItem('locatn'), addrss = localStorage.getItem('addrss');
  // if (!locatn) { console.log('Will retry reading from localStorage after 5 sec');
  //   setTimeout(() => {
  //     locatn = localStorage.getItem('locatn'); addrss = localStorage.getItem('addrss');
  //     console.log('locatn:', locatn, ', addrss:', addrss);
  //   }, 5000);
  // }
  const initialValues = { email: '', phone: '', name: '', locatn, address: addrss };
  //console.log('ShippingAddress:: initialValues:', initialValues);
  return (
    <div>
      <h4>Shipping Address</h4>
      <Formik initialValues={initialValues} validate={validate}
        onSubmit={(values, actions) => { localStorage.setItem('locatn', values.locatn);
        localStorage.setItem('addrss', values.address);
        setAddrTaken(!addrTaken); setShipping(values); actions.setSubmitting(false); }}>{
        ({ values, errors, handleChange, handleSubmit, isSubmitting }) => {
          if(user) {
            if(!values.name && user.displayName) values.name = user.displayName;
            if(!values.phone && user.phone) values.phone = user.phone.substring(3);
            if(!values.email && user.email &&
              !(user.email[0]==='p' && user.email[1]==='_')) values.email = user.email;
          } //console.log('values:', values);
          const { name, phone, address, email } = errors;
          return (<Form //form onSubmit={handleSubmit}
            >
              {/* <div><input type='text' name='name' onChange={handleChange} value={values.name}
                placeholder='Name' className={'nomad-input '+(name?'error':'')} disabled={addrTaken} />
              </div> */}
              <div><Field type='text' name='name' value={values.name} //onChange={handleChange}
                placeholder='Name' className={'nomad-input '+(name?'error':'')}
                disabled={addrTaken} required /></div>
              <div><Field type='text' name='phone' value={values.phone} //onChange={handleChange}
                placeholder='Phone' className={'nomad-input '+(phone?'error':'')}
                disabled={addrTaken} required /></div>
              <div><Field type='email' name='email' value={values.email} //onChange={handleChange}
                placeholder='Email' className={'nomad-input '+(email?'error':'')}
                disabled={addrTaken} required /></div>
              <div><Field type='text' name='address' value={values.address}
                placeholder='Address' className={'nomad-input '+(address?'error':'')}
                disabled={addrTaken} required /></div>
              <div><Field as='select' name='locatn' value={values.locatn} //id='locatn'
                disabled={addrTaken} className={'nomad-input'}>
                <option value='Banani'>Banani</option>
                <option value='Banani DOHS'>Banani DOHS</option>
                <option value='Mohakhali DOHS'>Mohakhali DOHS</option>
                <option value='Gulshan'>Gulshan</option>
                <option value='Baridhara'>Baridhara</option>
                <option value='Baridhara DoHS'>Baridhara DoHS</option>
              </Field></div>
              <div className='submit-btn'><button type='submit' className={
                `button is-${addrTaken?'grey':'black'} nomad-btn submit`}>{ //white
                addrTaken? 'CHANGE': 'CONTINUE'}</button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ShippingAddress;
