import { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CartContext } from '../../../context/cart-context';
import { useNavigate } from 'react-router-dom';
import Layout from '../../shared/layout';

const Success = () => {
  const { clearCart, cartItems } = useContext(CartContext);
  const [ searchParams ] = useSearchParams();
  const bkTranStat = searchParams.get('bkTranStat');
  const navigate = useNavigate(); console.log('bkTranStat:', bkTranStat);
  useEffect(() => {
    if(cartItems.length>0) clearCart();
  }, [clearCart, cartItems]);
  return (
    <Layout>
      <div className='checkout'>
        <h1>Thank you for your {bkTranStat==='Completed'? 'bKash Payment': 'order'}</h1>
        {/* <p>We are currently processing your order and will send you a confirmation email shortly</p> */}
        <p>We are currently processing your order and will contact you soon</p>
        <div>
          <button className='button is-black nomad-btn submit'
            onClick={() => navigate('/shop')}>Continue Shopping</button>
        </div>
      </div>
    </Layout>
  );
};

export default Success;
