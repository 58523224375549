import { StyleSheet } from '@react-pdf/renderer';

// Create Styles
const styles = StyleSheet.create({
  page: { position: "relative",
    flexDirection: "row", padding: "48px 48px 0 36px", // A4  //, backgroundColor: "#f4f4f4"
    // flexDirection: "row", backgroundColor: "#f4f4f4", padding: "36px 148px 0 36px", // B4
    // flexDirection: "row", backgroundColor: "#f4f4f4", padding: "36px 79px 0 30px", // B4 - 124px 0 32px
  },
  section: {
    margin: "10px 0 10px 10px", padding: "10px", flexGrow: 1, fontSize: "12px", //10px 0 10px 10px
    fontFamily: "Roboto" //Bottom: 10px, 10px //, 'Helvetica Neue', sans-serif
  },
  window: { height: "99vh", width: "99.4%" },  //87.45vh, 54% //81.65vh, 98%
  big: { fontSize:'36px', color:'grey', marginLeft:'-3.1px' }, //'#e9e9e9'
  btn: { color: '#000', backgroundColor: '#c9c9ff', position: 'absolute', left: '67vw', top: '1px' },
  img: { width: '70px', height: '70px', position: 'absolute', top: '40px', right: '45px' },
  canvas: { position: 'absolute', top: '40px', right: '45px',
    backgroundColor: "rgba(255,255,255,0.2)", height: '70px', width: '70px',
  },
  center: { textAlign: "center" },
  flex1: { flex: 1 }, flex2: { flex: 2 }, flex3: { flex: 3 },
  footer: { position: 'absolute', bottom: '40px', left: '56px', width: '88vw' },
  grey: { color: 'grey' },
  h1: {fontSize:'20px', fontWeight:'bold', textAlign:'center'}, //18px
  line: { flexDirection: 'row' },
  pb8: { paddingBottom: '8px' },
  pr12: { paddingRight: '12px' },
  size10: { fontSize: "10px" },
  size12: { fontSize: "12px" },
  size14: { fontSize: "14px", paddingBottom: "8px" },
  right: { textAlign: "right" },
});

export default styles;
