import './footer.styles.scss';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className='footer'>
      {year} &copy; ORKO Farms
    </div>
  );
};

export default Footer;
