import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/user-context';

const Total = ({ itemCount, qtyCount, total, clearCart }) => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext); console.log('user:', user); //, checkUser

  useEffect(() => { //console.log('user:', user);
    if(!user) { console.log('cartPage - Total: checking user in session storage');
      let usrFmStor = sessionStorage.getItem('user'); //console.log('usrFmStor:', usrFmStor);
      let usr = null; if(usrFmStor) usr = JSON.parse(usrFmStor);
      if(usr&& usr.uid) { setUser(usr); console.log('Session usr:', usr);
    } }
  }, [setUser]);

  return (
    <div className='total-container'>
      <h2>Order Summary</h2>
      <div className='total'>
        <p>Number of Items: {itemCount}</p>
        <p>Total quantity: {qtyCount}</p>
        <p>{`Total: Tk ${total}/-`}</p>
      </div>
      <div className='checkout'>
        { user?
          <button className='button is-black' onClick={() => navigate('/checkout')}>CONFIRM</button>:
          <button className='button is-black' onClick={() => navigate('/sign-in')}>SIGN IN</button>
        }
        <button className='button is-white' onClick={() => clearCart()}>CLEAR</button>
      </div>
    </div>
  );
};

export default Total;
