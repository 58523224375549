import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
// import { authOld } from '../../firebase'; //, auth
import { UserContext } from '../../context/user-context';
import { OrdersContext } from '../../context/orders-context';
import LocatnPhoneModal from '../shared/locatn-phonmodal';
import CartIcon from '../cart-icon/cart-icon';
import './header.styles.scss';

const Header = ({setShopping, setShModal}) => { //console.log("Header");
  const { user, setUser } = useContext(UserContext); //console.log("Context user:", user);
  const { orders, refreshOrders, clearOrders } = useContext(OrdersContext);
  //console.log('Header:: orders:', orders);
  const auth = getAuth(); //console.log("Header:: auth:", auth);
  //localStorage.setItem('location', 'Gulshan');
  //const locatn = localStorage.getItem('locatn'); //'Banani';
  const [locatn, setLocatn ] = useState(localStorage.getItem('locatn'));
  const [showModal, setShowModal ] = useState(false); //console.log('locatn:', locatn);
  const [modalShown, setModalShown ] = useState(false);

  const checkUser = () => { //orders, refreshOrders
    let type = '', usrFmStor = sessionStorage.getItem('user'); //console.log('usrFmStor:', usrFmStor);
    let usr = null; if(usrFmStor) usr = JSON.parse(usrFmStor);
    if(sessionStorage.getItem('signedOut')==='true') { console.log('Signed Out');
      usr = null; setUser(null); sessionStorage.removeItem('signedOut');
    }
    const getPhoneType = async (uid, displayName, email, phone) => { if(auth.currentUser) {
      const idTokenResult = await auth.currentUser.getIdTokenResult(true);
      if(idTokenResult&& idTokenResult.claims) { //console.log('idTokenResult.claims.type:', idTokenResult.claims.type);
        if(!phone) phone = idTokenResult.claims.phone;
        type = idTokenResult.claims.type; //console.log('Current User type:', type, phone); //, uid, displayName, email
        if(email && !phone) { //Temporary code to set phone
          if(email[0]==='p' && email[1]==='_') { phone = email.substring(2);
            phone = phone.split('@')[0]; console.log('phone:', phone);
        } } usr = { uid, displayName, email, phone, type };
        setUser(usr); sessionStorage.setItem('user', JSON.stringify(usr));
        //console.log('auth.currentUser:', auth.currentUser);
    } } }; //console.log('usr&& usr.uid:', (usr&& usr.uid), usr);
    if(usr&& usr.uid) { setUser(usr); console.log('Session usr:', usr);
      if(!usr.type) getPhoneType(usr.uid, usr.displayName, usr.email, usr.phone);
      if(orders&& orders.length < 1) refreshOrders(); //console.log('orders:', orders);
    } else
    if(auth.currentUser) { const { uid, displayName, email, phoneNumber } = auth.currentUser;
      let phone = ''; if(phoneNumber) phone = phoneNumber;
      usr = { uid, displayName, email, phone, type }; //console.log('phoneNumber:', phoneNumber);
      setUser(usr); sessionStorage.setItem('user', JSON.stringify(usr));
      getPhoneType(uid, displayName, email, phone); //console.log('phone:', phone, ', type:', type);
      if(orders&& orders.length < 1) refreshOrders(); //console.log('orders:', orders);
    } else setUser(null); return auth.currentUser;
  }

  useEffect(() => { //console.log('auth.currentUser:', auth.currentUser);
    if(!checkUser()) setTimeout(() => {
      if(!checkUser()) setTimeout(() => { checkUser(); }, 900);
    }, 900);
  }, [auth.currentUser,orders.length,setUser]); //refreshOrders,

  return (<>
      {showModal&& <LocatnPhoneModal locatn={locatn} setLocatn={setLocatn} //!modalShown&&
        modalShown={modalShown} setModalShown={setModalShown} />}
      <nav className='nav-menu container header'>
        <div className='logo'>
          {/* <Link to='/'>ORKO Farms</Link> */}
          <Link to='/'>
            <img src='orkofarms.png' title='ORKO Farms' /> <span className='text'>ORKO FARMS</span>
          </Link>
        </div>
        <ul>
          {(!user || user.type!=='D') && <li><div><Link to='/'>Home</Link></div></li>}
          {/* <li><div className='hide-in-mobile'><Link to='/shop'>Shop</Link></div></li> */}
          {!user && <li><Link to='/sign-in'>Sign In</Link></li>}
          {/* {!user && <li className='sign-up'><Link to='/sign-up'>Register</Link></li>} */}
          {user? (['A','M','E'].includes(user.type)? <li><Link to='/admin'>Admin</Link></li>:
            orders.length>0 && <li><Link to='/orders'>Orders</Link></li>): null}
          {user && <li className='sign-out' onClick={async () => { //console.log("Signing Out");
            await signOut(auth); setUser(null); sessionStorage.removeItem('user'); clearOrders();
            if(setShModal) setShModal(false); if(setShopping) setShopping(false); }}>
          <div className={user && user.type!=='D'?'hide-in-mobile':''}>Sign Out</div></li>}

          {locatn&& <li>
            <div className='hide-in-mobile'>{locatn?<span>Location: <b>{locatn}</b></span>:''}</div>
          </li>}

        </ul>
        <CartIcon user={user} setShowModal={setShowModal} />
      </nav>

      {/* <div style={{ backgroundColor:'darkgreen', color: 'white', position:'relative', zIndex:'2',
        marginTop:'-10px', paddingBottom:'10px' }}>
        <div className='locatn'>{locatn?<span>Location: <b>{locatn}</b></span>:''}</div>
        {user && <div className='user'>{user.displayName? user.displayName: (user.phone? user.phone: user.email)}</div>}
      </div> */}

    </>
  );
};

export default Header;
