import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/user-context';
import Layout from './shared/layout';
import Hero from './hero/hero';
// import MainSection from './main-section/main-section';
import FeaturedCollection from './featured-collection/featured-collection';

const HomePage = () => { //console.log("HomePage");
  const { user } = useContext(UserContext), navigate = useNavigate();
  useEffect(() => { if(user && user.type==='D') navigate('/orders'); });
  const [shopping, setShopping ] = useState(false);
  const [shModal, setShModal] = useState(false); if(shopping&& !shModal) setShModal(true);
  return (
    <Layout setShopping={setShopping} setShModal={setShModal}>
      <Hero />
      {/* <MainSection /> */}
      <FeaturedCollection shopping={shopping} setShopping={setShopping} shModal={shModal} />
      {/* <div className='shop-now-btn'>
        <button className='button is-black' id='shop-now'
          onClick={() => navigate('/shop')}>ALL PRODUCTS
        </button>
      </div> */}
    </Layout>
  );
};

export default HomePage;
