import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import shoppingBag from '../../assets/shopping-bag.png';
import shoppingCart from '../../assets/cart.png';
import { CartContext } from '../../context/cart-context';
import './cart-icon.styles.scss';

const CartIcon = (props) => {
  const { itemCount, cartItems } = useContext(CartContext);
  const { user, setShowModal } = props;
  const navigate = useNavigate();
  //console.log("cartItems:", cartItems);
  return (
    <div className='cart-container' onClick={() => {
      if(user) navigate('/cart'); else setShowModal(true); }}>
      <img src={shoppingCart} alt='shopping-cart-icon' //shoppingBag
      />
      {
        itemCount > 0 ? <span className='cart-count'> {itemCount} </span> : null
      }
    </div>
  );
};

export default CartIcon;
