import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import { getAuth, createUserWithEmailAndPassword, updateProfile, RecaptchaVerifier,
  signInWithPhoneNumber, linkWithCredential, EmailAuthProvider } from 'firebase/auth';
import { createUserProfileDocument } from '../../firebase'; //, authOld, auth
import { UserContext } from '../../context/user-context';
import { fetchFromAPI } from '../../helpers';
import Layout from '../shared/layout';
import './sign-up.styles.scss';

const validate = values => {
  const errors = {};
  if(!values.phone) errors.phone  = 'Phone number is Required';
  else if(!/^01[0-9]{8,}$/i.test(values.phone)) errors.phone = 'Invalid phone number';
  // if(!values.email) errors.email = 'Required';
  // else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
  //   errors.email = 'Invalid email address';
  // }
  if(!values.firstname) errors.firstname  = 'Name is Required';
  if(!values.password) errors.password = 'Password is Required';
  else if(values.password.length<6) errors.password = 'Password must be at least 6 characters long';
  return errors;
}

const SignUp = () => {
  const [error, setError] = useState(null);
  const [cnfmObj, setCnfmObj] = useState(null);
  const [otpVrfd, setOtpVrfd] = useState(false);
  const { user, setUser } = useContext(UserContext); //console.log("user:", user);
  const navigate = useNavigate(), auth = getAuth();
  const initialValues = { firstname: '', phone: '', email: '', password: '', otp: '' };

  useEffect(() => {
    const setUpRecaptcha = () => {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth, 'recaptcha-container', { size: 'invisible', callback: (resp) => {
          console.log('callback:: reCAPTCHA solved, allow signInWithPhoneNumber');
        } }
      ); window.recaptchaVerifier.verify();
    }; setUpRecaptcha();
  }, [auth]);

  // const handleSignUp_Old = async (values, { setSubmitting }) => {
  //   const { firstname, email, password } = values; //console.log("handleSignUp:: values:", values);
  //   try {
  //     const { user } = await authOld.createUserWithEmailAndPassword(email, password);
  //     await createUserProfileDocument(user, { displayName: firstname });
  //     setSubmitting(false); navigate('/shop');
  //   } catch(error) { console.log(error);
  //     setSubmitting(false); setError(error);
  //   }
  // };

  const handleSignUp = async (values, { setSubmitting }) => {
    let { firstname, phone, email, password, otp } = values; console.log("handleSignUp:: values:", values);
    if(!email) email = 'p_' + phone + '@test.com'; console.log('email:', email);
    if(firstname) {
      if(!cnfmObj) {
        const appVerifier = await window.recaptchaVerifier;
        const obj = await signInWithPhoneNumber(auth, '+88' + phone, appVerifier);
        setCnfmObj(obj); console.log('obj:', obj);
      }

      if(cnfmObj && otp) { let usr = null; //const otp = '123456'; //phone==='+8801234567890' &&
        try {
          const res = await cnfmObj.confirm(otp); console.log('Success res:', res);
          const { phoneNumber } = res.user; usr = res.user; console.log('phoneNumber:', phoneNumber);
          //-------------------------------------------------------------------------------//
          // if(phoneNumber) { console.log("Creating user with firstname:", firstname); //otpVrfd
          //   try {
          //     // const { user } = await authOld.createUserWithEmailAndPassword(email, password);
          //     const { user } = await createUserWithEmailAndPassword(auth, email, password);
          //     console.log("Sign-up:: auth.currentUser:", auth.currentUser);
          //     await updateProfile(auth.currentUser, { displayName: firstname });
          //     //console.log("getAuth().currentUser:", getAuth().currentUser);
          //     //console.log("auth.currentUser:", auth.currentUser, ", user:", user);
          //     if(user) {
          //       await createUserProfileDocument(user, { displayName: firstname });
          //       const { uid, displayName, email } = user; setUser({ uid, displayName, email });
          //     } else setUser(null);
          //     setSubmitting(false); navigate('/shop');
          //   } catch(error) { console.log(error);
          //     setSubmitting(false); setError(error); setUser(null);
          // } }
          //-------------------------------------------------------------------------------//
        } catch(e) {
          console.log('Otp error:', e);
        } finally {
          const updateUser = async () => { //console.log('usr:', usr);
            const body = { uid: usr.uid,
            displayName: firstname, phoneNumber: usr.phoneNumber, email, password };
            const res = await fetchFromAPI('update-user', {body}); console.log('res:', res);
            // await createUserProfileDocument(usr, { displayName: firstname, email });
            // const { uid, phoneNumber } = usr; setUser({ uid, displayName: firstname, phoneNumber, email });
          };
          if(usr) {
            try { updateUser();
              setUser({ uid: usr.uid, displayName: firstname, phoneNumber: usr.phoneNumber, email });
              setSubmitting(false); navigate('/shop');
            }
            catch(error) { console.log('Error updating user:', error);
              setSubmitting(false); setError(error); setUser(null);
            }
          }
          // const credential = EmailAuthProvider.credential(email, password);
          // linkWithCredential(user, credential) //auth.currentUser
          // .then((usercred) => { console.log('Linked successfully, usercred:', usercred);
          //   // The provider is now successfully linked.
          //   // The phone user can now sign in with their phone number or email.
          // })
          // .catch((error) => { console.log('Link error:', error);
          //   // Some error occurred.
          // });
        }
    } }
  };

  return (
    <Layout>
      <div className='sign-up'>
        <h1>Sign Up</h1>
        <div id='recaptcha-container'></div>
        <div className='form-container'>
          <Formik initialValues={initialValues} validate={validate}
            onSubmit={handleSignUp //values => {console.log(values)}
            }>{({values, errors, handleChange, handleSubmit, isSubmitting}) => {
              console.log("errors", errors, ", values:", values); //, email
              const { firstname, phone, password } = errors; // onSubmit={handleSubmit}
              return <Form>
                <div><Field type='text' name='firstname' //onChange={handleChange} //input
                  value={values.firstname} placeholder='Name'
                  className={'nomad-input ' + (firstname? 'error': '')} required /></div>
                <div><Field type='text' name='phone' //onChange={handleChange}
                  value={values.phone} placeholder='Mobile Number'
                  className={'nomad-input ' + (phone? 'error': '')} required /></div>
                <div><Field type='email' name='email' //onChange={handleChange}
                  value={values.email} placeholder='Email (Optional)' // + (email? 'error': '')
                  className={'nomad-input '} /></div>
                <div><Field type='password' name='password' //onChange={handleChange}
                  value={values.password} placeholder='Password'
                  className={'nomad-input ' + (password? 'error': '')} required /></div>
                {(firstname|| phone|| password) && <div className='error-message'>
                  { <p>{firstname? firstname: (phone? phone: password)}</p> }
                </div>}
                {!cnfmObj && <div className='submit-btn'><button type='submit' disabled={isSubmitting}
                  className='button is-black nomad-btn submit'>Submit</button></div>}
                <div className='error-message'>{ error && <p>{error.message}</p> }</div>
                {cnfmObj && <><div><p>Enter the Verification Code (OTP) sent to your Mobile</p>
                  <input type='text' name='otp' onChange={handleChange} value={values.otp}
                  placeholder='Verification Code (OTP)' className={'nomad-input '} /></div>
                <div className='submit-btn'><button type='submit' disabled={isSubmitting}
                  className='button is-black nomad-btn submit'>Verify OTP</button></div></>}
              </Form>;
            }}
          </Formik>
        </div>
      </div>
    </Layout>
  );
};

export default SignUp;
