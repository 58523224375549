import { useContext, useEffect, useState } from 'react';
import { ProductsContext } from '../../context/products-context';
import LocatnPhoneModal from '../shared/locatn-phonmodal';
import FeaturedProduct from '../shared/featured-product';

const FeaturedCollection = ({shopping, setShopping, shModal}) => {
  const { products } = useContext(ProductsContext);
  const [locatn, setLocatn ] = useState(localStorage.getItem('locatn'));
  //const [shopping, setShopping ] = useState(false); //console.log('locatn:', locatn);
  const [bAdd, setbAdd] = useState(false), [bInc, setbInc] = useState(false);
  const [ prd, setPrd ] = useState(null);
  const [modalShown, setModalShown ] = useState(false);
  //const [shModal, setShModal] = useState(false); if(shopping&& !shModal) setShModal(true);
  const [animate, setAnimate] = useState(true); //console.log('animate:', animate);
  const [animCls, setAnimCls] = useState(''); //console.log('animCls:', animCls);
  //const [si, setSi] = useState(0); //console.log('si:', si);
  const [activeProducts, setActiveProducts] = useState(null); let productItems = null;
  const numProds = products.length; //console.log('numProds:', numProds);
  if(numProds>1 && !activeProducts) setActiveProducts(products); //.filter(product => product.active)
  if (activeProducts) { //products
    productItems = /*products.filter((product, i) => {
      return i < 5; //i>=si && i<(si+6);
    })*/ activeProducts
    .map(product => (
      <FeaturedProduct {...product} setShopping={setShopping} setbAdd={setbAdd}
      setbInc={setbInc} setPrd={setPrd} modalShown={modalShown} key={product.id} />
    ));
  }
  useEffect(() => { //console.log('useEffect');
    if(animate && numProds>1) { const intrval1 = setInterval(() => {
        setAnimCls(animCls => { //console.log('animCls:', animCls);
          return (animCls? '': 'animate');
        });
      }, 1800); const intrval2 = setInterval(() => {
        setActiveProducts(oldProducts => { //console.log('oldProducts:', oldProducts, numProds);
          const newProducts = oldProducts.map(prd => prd); //console.log('newProducts:', newProducts);
          const firstProd = newProducts.shift();
          newProducts.push(firstProd); //console.log('newProducts:', newProducts);
          return newProducts;
        });
      }, 3600); return () => { clearInterval(intrval1); clearInterval(intrval2); };
    }
  }, [numProds]); //console.log('products:', products);
  return (<>
    {shModal&& <LocatnPhoneModal locatn={locatn} setLocatn={setLocatn} shopping={shopping}
      setShopping={setShopping} modalShown={modalShown} setModalShown={setModalShown}
      bAdd={bAdd} setbAdd={setbAdd} bInc={bInc} setbInc={setbInc} prd={prd}  />}
    <div className='featured-collection container'>
      <h2 className='featured-section-title'>Featured Collection</h2>
      <div className={'products ' + animCls}>
        {productItems}
      </div>
    </div>
  </>);
};

export default FeaturedCollection;
