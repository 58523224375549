import Header from '../header/header';
import Footer from '../footer/footer';

const Layout = ({ children, setShopping, setShModal }) => { //console.log("Layout:: children:", children);
  return (
    <>
      <Header setShopping={setShopping} setShModal={setShModal} />
      <main>
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
