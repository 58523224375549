import { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CartContext } from '../../../context/cart-context';
import { useNavigate } from 'react-router-dom';
import Layout from '../../shared/layout';

const OrderFailed = () => {
  const { clearCart, cartItems } = useContext(CartContext);
  const [ searchParams ] = useSearchParams();
  const bkTranStat = searchParams.get('bkTranStat');
  const navigate = useNavigate(); console.log('bkTranStat:', bkTranStat);
  // useEffect(() => {
  //   if(cartItems.length>0) clearCart();
  // }, [clearCart, cartItems]);
  return (
    <Layout>
      <div className='checkout'>
        <h1>{ bkTranStat==='Insuffucient'? 'Sorry! Insufficient Fund!!'
          : 'Sorry the order could not be placed'}</h1>
        {/* <p>We are currently processing your order and will send you a confirmation email shortly</p> */}
        <p>{ bkTranStat? 'Your bKash payment was\'nt successful because of ' +
          (bkTranStat==='Insuffucient'? 'Insufficient Fund': 'Incorrect Credentials')
          : 'We could not place your order because of some technical issues'
        }</p>
        <div>
          <button className='button is-black nomad-btn submit'
            onClick={() => navigate('/checkout')}>Go Back</button>
        </div>
      </div>
    </Layout>
  );
};

export default OrderFailed;
